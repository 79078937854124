import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import ModalCard from '../../app-components/modal-card'
import Icon from '../../app-components/icon'

const SessionBookingModal = ({
  show,
  type,
  studio,
  date,
  actions,
  toggleModal,
  phoneForm,
  isDisabled,
  error,
}) => (
  <ModalCard
    heading={
      (type === 'confirm' && 'Votre séance') ||
      (type === 'conflict' && 'Créneau non disponible') ||
      ((type === 'modifier' || type === 'update' || type === 'update-limit') &&
        'Modifier ma séance') ||
      ((type === 'annuler' || type === 'cancel-limit') && 'Annuler ma séance')
    }
    icon={
      ((type === 'confirm' || type === 'update') && (
        <Icon name="icon-schedule" iconClass="card-body-icon" paths={2} />
      )) || <Icon name="icon-ic-info" iconClass="card-body-icon" />
    }
    title={
      ((type === 'confirm' || type === 'update') && (
        <>
          <p className="subtitle">Votre séance aura lieu au:</p>
          <p className="studio-name">Studio {studio.name}</p>
          <p className="studio-address">
            {studio.address}, {studio.postalcode} {studio.city}
          </p>
          <p className="studio-date-time text-capitalize">
            {date.format('dddd DD MMMM')} -{' '}
            {date.format('HH:mm').replace(':', 'h')}
          </p>
          {type === 'confirm' && phoneForm()}
        </>
      )) ||
      (type === 'update-limit' && (
        <>
          <p className="subtitle">Votre séance est dans moins de 24h00. </p>
          <p>
            <b>Vous ne pouvez plus modifier votre réservation.</b>
            <br />
            <br />
            Merci d’envoyer un message à notre service client via l’adresse{' '}
            <b>hello@booke.fr</b> ou de nous contacter par téléphone.
          </p>
        </>
      )) ||
      (type === 'modifier' && (
        <>
          <p className="subtitle">
            Vous pouvez modifier votre séance <strong>jusqu’à 24h</strong> avant
            la séance.
          </p>
        </>
      )) ||
      (type === 'cancel-limit' && (
        <>
          <p className="subtitle">Votre séance est dans moins de 48h00. </p>
          <p>
            <b>Vous ne pouvez plus annuler votre réservation.</b>
            <br />
            <br />
            Merci d’envoyer un message à notre service client via l’adresse{' '}
            <b>hello@booke.fr</b> ou de nous contacter par téléphone.
          </p>
        </>
      )) ||
      (type === 'annuler' && (
        <>
          <p className="subtitle">
            Vous pouvez annuler votre séance <strong>jusqu’à 48h </strong> avant
            la séance.
          </p>
          <p className="subtitle">
            Attention, les séances annulées à moins de 48h seront facturées à
            l’entreprise.
          </p>
        </>
      )) ||
      (type === 'conflict' && (
        <>
          <p className="subtitle">
            Ce créneau n&apos;est plus disponible. Veuillez choisir un nouvel
            horaire.
          </p>
        </>
      ))
    }
    show={show}
    onHide={() => toggleModal(false)}
    className={`gallery-modal-card session-booking ${type}`}
  >
    {error !== '' && (
      <Row className="text-center w-100">
        <Col>
          <span className="error-label">{error}</span>
        </Col>
      </Row>
    )}
    <Row className="w-100 text-center">
      <Col>
        {(type === 'confirm' && (
          <Button
            onClick={actions.confirm}
            variant="secondary"
            size="lg"
            className={`card-modal-button ${error !== '' ? 'error' : ''}`}
            disabled={isDisabled}
          >
            Confirmer
          </Button>
        )) ||
          (type === 'conflict' && (
            <Button
              onClick={() => toggleModal(false)}
              variant="secondary"
              size="lg"
              className={`card-modal-button ${error !== '' ? 'error' : ''}`}
            >
              Modifier mon choix
            </Button>
          )) ||
          (type === 'update' && (
            <Button
              onClick={actions.update}
              variant="secondary"
              size="lg"
              className={`card-modal-button ${error !== '' ? 'error' : ''}`}
            >
              Confirmer
            </Button>
          )) ||
          (type === 'modifier' && (
            <Button
              onClick={actions.modify}
              variant="secondary"
              size="lg"
              className="card-modal-button"
            >
              Modifier ma séance
            </Button>
          )) ||
          (type === 'annuler' && (
            <Button
              onClick={actions.cancel}
              variant="secondary"
              size="lg"
              className="card-modal-button"
            >
              Annuler ma séance
            </Button>
          ))}
      </Col>
    </Row>
  </ModalCard>
)
SessionBookingModal.propTypes = {
  show: propTypes.bool.isRequired,
  type: propTypes.string.isRequired,
  studio: propTypes.objectOf(propTypes.any).isRequired,
  date: propTypes.objectOf(propTypes.any).isRequired,
  actions: propTypes.objectOf(propTypes.func).isRequired,
  toggleModal: propTypes.func.isRequired,
  error: propTypes.string,
  phoneForm: propTypes.node,
  isDisabled: propTypes.bool,
}
SessionBookingModal.defaultProps = {
  error: '',
  phoneForm: null,
  isDisabled: true,
}
export default SessionBookingModal
