import React from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import propTypes from 'prop-types'
import './styles.scss'

const BackOfficeMenu = ({ isAccountManager }) => {
  const infos = isAccountManager ? 'entreprise' : 'personnelles'

  return (
    <Container className="back-office-menu">
      <Row>
        <Col />
        <Col xs="auto">
          <Nav variant="pills" className="menu-wrapper">
            <Nav.Item>
              <Nav.Link to="/compte/projets" eventKey="projects" as={Link}>
                Mes Projets
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="informations"
                to="/compte/informations"
                as={Link}
              >
                <span className="d-none d-lg-block">Informations {infos}</span>
                <span className="d-block d-lg-none">Infos {infos}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="password" to="/compte/mot-de-passe" as={Link}>
                Mot de passe
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col />
      </Row>
    </Container>
  )
}

BackOfficeMenu.propTypes = {
  isAccountManager: propTypes.bool.isRequired,
}
export default BackOfficeMenu
