import React from 'react'
import { Button } from 'react-bootstrap'
import propTypes from 'prop-types'
import ModalCard from '../../../app-components/modal-card'
import Icon from '../../../app-components/icon'
import ERRORS from '../../../../constants/errors'

const AddCollaboratorListErrorModal = ({ show, setShow, error }) => {
  const errorMsg = () => {
    if (error === 'Nothing added') {
      return ERRORS.collaborators.noAdded
    }
    return ERRORS.random
  }
  return (
    <ModalCard
      heading="Erreur"
      icon={
        <Icon
          name="icon-close"
          iconClass="card-body-icon checked-icon icon-wrapper p-5"
        />
      }
      title={<strong>{errorMsg()}</strong>}
      show={show}
      onHide={() => setShow(false)}
    >
      <Button
        onClick={() => {
          setShow(false)
        }}
        variant="primary"
      >
        Fermer
      </Button>
    </ModalCard>
  )
}
AddCollaboratorListErrorModal.defaultProps = {
  error: ERRORS.random,
}
AddCollaboratorListErrorModal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  error: propTypes.string,
}

export default AddCollaboratorListErrorModal
