import React, { useState } from 'react'
import { Col, Container, Form, Row, Button } from 'react-bootstrap'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import propTypes from 'prop-types'

import ModalCard from '../../../../app-components/modal-card'
import AddCollaboratorConfirmModal from '../AddCollaboratorConfirmModal'
import ErrorMessage from '../../../../app-components/form-error-message'
import './styles.scss'

const AddCollaboratorModal = ({ show, setShow, handleSubmit, project }) => {
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false)
  const [newCollab, setNewCollab] = useState({
    lastName: '',
    firstName: '',
    email: '',
    password: '',
    day: '',
    month: '',
    year: '',
  })
  /* todo select values  day, month, year */
  const { lastName, firstName, email, password } = newCollab

  const formSchema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup
      .string()
      .email()
      .max(100)
      .required(),
    password: yup
      .string()
      .min(8)
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).+$/),
  })
  const {
    register,
    errors,
    setError,
    clearError,
    formState,
    setValue,
    handleSubmit: wrapSubmit,
  } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
  })

  const handleChange = ({ target: { value, name } }) => {
    // validate form on input change
    setValue(name, value, true)
    clearError('collaborator')
    setNewCollab({ ...newCollab, [name]: value })
  }

  const doSubmit = async () => {
    const result = await handleSubmit(newCollab)
    if (result === true) {
      // show notify collaborator modal
      setShowAddConfirmModal(true)
      // hide modal
      setShow(false)
      // reset form
      setNewCollab({})
    } else {
      setError([{ name: 'collaborator', type: 'notAdded' }])
    }
  }

  return (
    <>
      <ModalCard
        heading="Nouveau collaborateur"
        className="add-collaborator"
        show={show}
        onHide={() => {
          setShow(false)
        }}
      >
        <Container className="add-collaborator-content" fluid>
          <Form onSubmit={() => wrapSubmit(doSubmit)}>
            <Row className="mt-3">
              <Col className="text-center">
                <h4>Informations</h4>
              </Col>
            </Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className="form-label-group"
                controlId="lastname"
              >
                <Form.Control
                  type="text"
                  placeholder="Nom"
                  name="lastName"
                  ref={register}
                  defaultValue={lastName}
                  onChange={handleChange}
                  isInvalid={typeof errors.lastName !== 'undefined'}
                />
                <Form.Label>Nom</Form.Label>
                <ErrorMessage name="lastName" errors={errors} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className="form-label-group"
                controlId="firstname"
              >
                <Form.Control
                  type="text"
                  placeholder="Prénom"
                  name="firstName"
                  ref={register}
                  defaultValue={firstName}
                  onChange={handleChange}
                  isInvalid={typeof errors.firstName !== 'undefined'}
                />
                <Form.Label>Prénom</Form.Label>
                <ErrorMessage name="firstName" errors={errors} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className="form-label-group"
                controlId="add-collab-email"
              >
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email professionnel"
                  ref={register}
                  defaultValue={email}
                  onChange={handleChange}
                  isInvalid={typeof errors.email !== 'undefined'}
                />
                <Form.Label>Email professionnel</Form.Label>
                <ErrorMessage name="email" errors={errors} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                className="form-label-group"
                controlId="default-password"
              >
                <Form.Control
                  type="text"
                  placeholder="Mot de passe par défaut"
                  name="password"
                  ref={register}
                  defaultValue={password}
                  onChange={handleChange}
                  isInvalid={typeof errors.password !== 'undefined'}
                />
                <Form.Label>Mot de passe par défaut</Form.Label>
                <ErrorMessage name="password" errors={errors} />
              </Form.Group>
            </Form.Row>
            <div className="text-center">
              <ErrorMessage name="collaborator" errors={errors} />
              <Button
                onClick={doSubmit}
                className="card-modal-button mt-4"
                size="lg"
                disabled={!formState.dirty || !formState.isValid}
                variant={
                  formState.dirty && formState.isValid ? 'secondary' : 'primary'
                }
              >
                AJOUTER
              </Button>
            </div>
          </Form>
        </Container>
      </ModalCard>
      <AddCollaboratorConfirmModal
        show={showAddConfirmModal}
        setShow={setShowAddConfirmModal}
        project={project}
      />
    </>
  )
}
AddCollaboratorModal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired,
  project: propTypes.objectOf(propTypes.any).isRequired,
}

export default AddCollaboratorModal
