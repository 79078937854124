import React from 'react'
import Badge from 'react-bootstrap/Badge'
import propTypes from 'prop-types'
import { PROJECT_STATUS, PICTURE_STATUS } from '../../../constants/status'

import './styles.scss'

const getStatus = status => {
  switch (status) {
    case PROJECT_STATUS.PENDING_PICTURES_SELECTIONS:
    case PROJECT_STATUS.PENDING_FINAL_PICTURES:
    case PICTURE_STATUS.PREVIEW:
    case PICTURE_STATUS.SELECTED:
      return 'En cours'
    case PROJECT_STATUS.FINISHED:
    case PICTURE_STATUS.FINAL:
      return 'Terminé'
    default:
      return 'En attente'
  }
}
const getStatusSlug = status => {
  switch (status) {
    case PROJECT_STATUS.PENDING_PICTURES_SELECTIONS:
    case PROJECT_STATUS.PENDING_FINAL_PICTURES:
    case PICTURE_STATUS.PREVIEW:
    case PICTURE_STATUS.SELECTED:
      return 'progress'
    case PROJECT_STATUS.FINISHED:
    case PICTURE_STATUS.FINAL:
      return 'done'
    default:
      return 'pending'
  }
}
const StatusBadge = ({ status, badgeClass }) => (
  <Badge pill className={`badge-${getStatusSlug(status)} ${badgeClass}`}>
    {getStatus(status)}
  </Badge>
)

StatusBadge.defaultProps = {
  badgeClass: '',
}

StatusBadge.propTypes = {
  status: propTypes.string.isRequired,
  badgeClass: propTypes.string,
}

export default StatusBadge
