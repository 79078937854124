/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import t from '../types'

axios.defaults.withCredentials = true

const getProjectListRequest = () => ({
  type: t.GET_PROJECTS_REQUEST,
})
const getProjectListSuccess = projects => ({
  type: t.GET_PROJECTS_SUCCESS,
  projects,
})
const getProjectListFailure = error => ({
  type: t.GET_PROJECTS_FAILURE,
  error,
})

export const doGetProjectList = () => async (dispatch, getState) => {
  try {
    dispatch(getProjectListRequest())
    const {
      auth: { user },
    } = getState()

    const { data } = await axios.get(
      `${process.env.GATSBY_BOOKER_URL}/users/${user.id}/projects`
    )
    dispatch(getProjectListSuccess(data))
  } catch (e) {
    dispatch(getProjectListFailure(e))
  }
}

// get all pictures from a Animations - Reportages Projects
const getReportageAnimationPicturesRequest = () => ({
  type: t.GET_REPORTAGE_ANIMATION_PICTURES_REQUEST,
})

const getReportageAnimationPicturesSuccess = (projectId, pictures) => ({
  type: t.GET_REPORTAGE_ANIMATION_PICTURES_SUCCESS,
  projectId,
  photos: pictures,
})

const getReportageAnimationPicturesFailure = error => ({
  type: t.GET_REPORTAGE_ANIMATION_PICTURES_FAILURE,
  error,
})

export const doGetReportageAnimationPictures = project => async dispatch => {
  try {
    dispatch(getReportageAnimationPicturesRequest())

    const { data } = await axios.get(
      `${process.env.GATSBY_BOOKER_URL}/B2BAnimationReportage/projects/${project.id}/pictures`
    )
    dispatch(getReportageAnimationPicturesSuccess(project.id, data))
  } catch (e) {
    dispatch(getReportageAnimationPicturesFailure(e))
  }
}

// download all pictures from a Animations - Reportages Projects
const downloadReportageAnimationPicturesRequest = () => ({
  type: t.DOWNLOAD_REPORTAGE_ANIMATION_PICTURES_REQUEST,
})

const downloadReportageAnimationPicturesSuccess = () => ({
  type: t.DOWNLOAD_REPORTAGE_ANIMATION_PICTURES_SUCCESS,
})

const downloadReportageAnimationPicturesFailure = error => ({
  type: t.DOWNLOAD_REPORTAGE_ANIMATION_PICTURES_FAILURE,
  error,
})

export const doDownloadAllReportageAnimationPictures = project => async dispatch => {
  try {
    dispatch(downloadReportageAnimationPicturesRequest())
    await axios.post(
      `${process.env.GATSBY_BOOKER_URL}/B2BAnimationReportage/projects/${project.id}/pictures/download/`
    )
    dispatch(downloadReportageAnimationPicturesSuccess())
  } catch (e) {
    dispatch(downloadReportageAnimationPicturesFailure(e))
  }
}

// download all pictures from a project
const downloadProjectPicturesRequest = () => ({
  type: t.DOWNLOAD_PROJECT_PICTURES_REQUEST,
})

const downloadProjectPicturesSuccess = () => ({
  type: t.DOWNLOAD_PROJECT_PICTURES_SUCCESS,
})

const downloadProjectPicturesFailure = error => ({
  type: t.DOWNLOAD_PROJECT_PICTURES_FAILURE,
  error,
})

export const doDownloadAllPictures = (
  collaborators,
  project
) => async dispatch => {
  try {
    dispatch(downloadProjectPicturesRequest())
    let reqData = {}
    if (collaborators.length > 0) {
      const list = []
      Object.keys(collaborators).forEach(collab => {
        list.push(collaborators[collab].id)
      })
      reqData = { usersId: list }
    }
    await axios.post(
      `${process.env.GATSBY_BOOKER_URL}/projects/${project.id}/pictures/download/`,
      reqData
    )
    dispatch(downloadProjectPicturesSuccess())
  } catch (e) {
    dispatch(downloadProjectPicturesFailure(e))
  }
}
