import React from 'react'
import { Button } from 'react-bootstrap'
import propTypes from 'prop-types'
import connect from 'react-redux/es/connect/connect'
import axios from 'axios'
import ModalCard from '../../../app-components/modal-card'
import { getUser } from '../../../../store/authentication/selectors'

const AddCollaboratorConfirmModal = ({
  show,
  setShow,
  project,
  user,
  collaboratorsAdded,
}) => {
  const notifyNewCollaboratorByEmail = async () => {
    await axios.post(
      `${process.env.GATSBY_BOOKER_URL}/notifyNewCollaboratorByEmail`,
      {
        manager_id: user.id,
        project_id: project.id,
        users: JSON.parse(JSON.stringify(collaboratorsAdded)),
      },
      {
        headers: { 'content-type': 'application/json' },
      }
    )
  }
  return (
    <ModalCard
      heading="Confirmation"
      icon={
        <span className="card-body-icon checked-icon icon-wrapper icon-checked" />
      }
      title={
        <>
          <strong>Votre nouveau collaborateur a bien été ajouté.</strong> <br />
          Souhaitez-vous le notifier par mail de la création de son compte ?
        </>
      }
      show={show}
      onHide={() => setShow(false)}
    >
      <Button
        onClick={() => {
          notifyNewCollaboratorByEmail()
          setShow(false)
        }}
        size="lg"
        variant="primary"
        className="mt-4 mx-2"
      >
        OUI
      </Button>
      <Button
        onClick={() => {
          setShow(false)
        }}
        size="lg"
        variant="outline-primary"
        className="mt-4 mx-2"
      >
        NON
      </Button>
    </ModalCard>
  )
}

AddCollaboratorConfirmModal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  project: propTypes.objectOf(propTypes.any).isRequired,
  user: propTypes.objectOf(propTypes.any).isRequired,
  collaboratorsAdded: propTypes.arrayOf(propTypes.any).isRequired,
}

const mapStateToProps = state => ({
  user: getUser(state),
  collaboratorsAdded: state.collaborator.collaboratorsAdded,
})

export default connect(mapStateToProps)(AddCollaboratorConfirmModal)
