import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { getUser } from '../../../store/authentication/selectors'

const PrivateRoute = ({ location, user, element }) => {
  if (user === null && location.pathname !== `/connexion`) {
    if (typeof window !== 'undefined') {
      navigate('/connexion')//{ state: { path: location.pathname } }
    }
    return null
  }
  return element
}

const mapStateToProps = state => ({
  user: getUser(state),
})

PrivateRoute.propTypes = {
  element: propTypes.element.isRequired,
  location: propTypes.objectOf(propTypes.any).isRequired,
  user: propTypes.objectOf(propTypes.any),
}
PrivateRoute.defaultProps = { user: null }

export default connect(mapStateToProps)(PrivateRoute)
