import { createSelector } from 'reselect'
import last from 'lodash/last'
import { slug } from '../../helpers'

export const getPhotos = ({ project }) => project.photos

export const getProjectLoading = ({ project }) => project.isLoading
export const getProjects = ({ project }) => project.projects
// return B2C project slug
export const getProjectSlug = (state, project) => {
  if (project.s3_root_dir) return slug(last(project?.s3_root_dir?.split('/')))
  return ''
}
const getProjectSlugFromProps = (state, props) => props.projectSlug

export const getProjectBySlug = createSelector(
  [getProjects, getProjectSlugFromProps],
  (projects, projectSlug) => {
    const project = projects.find(
      p =>
        slug(p.name) === projectSlug ||
        slug(last(p.s3_root_dir?.split('/'))+"") === projectSlug // B2C slug format
    )
    return project || null
  }
)

export const getPhotosFromProject = createSelector(
  [getProjectBySlug, getPhotos],
  (project, photos) => (typeof photos !== 'undefined' ? photos : [])
)
