import React, { useRef } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import propTypes from 'prop-types'

import Icon from '../../../../app-components/icon'
import './styles.scss'

const EmptyCollaboratorList = ({
  handleAddCollaborator,
  handleAddCollaboratorList,
  noSearchResults,
}) => {
  const importCsv = useRef(null)

  return (
    <Row className="empty-collaborator-list">
      <Col xs={12} lg={{ span: 4, offset: 4 }} className=" text-center">
        <Icon name="icon-add-collaborator" paths={4} iconClass="empty-icon" />
        {noSearchResults}
        {noSearchResults && (
          <p className="mt-3">
            Aucun résultat ne correspond à votre recherche. Vous pouvez modifier
            votre recherche ou ajouter un nouveau collaborateur.
          </p>
        )}
        {!noSearchResults && (
          <p className="mt-3">
            Vous n&apos;avez <b>aucun collaborateur</b> pour le moment. Vous
            pouvez aussi importer un CSV.
          </p>
        )}
        <Button
          className="mb-3"
          variant="secondary"
          size="lg"
          onClick={handleAddCollaborator}
        >
          AJOUTER UN COLLABORATEUR
        </Button>
        <br />
        <Button
          variant="outline-primary"
          size="lg"
          onClick={() => {
            importCsv.current.value = null
            importCsv.current.click()
          }}
        >
          IMPORTER UN CSV
        </Button>
        <input
          id="import-csv"
          ref={importCsv}
          className="d-none"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={handleAddCollaboratorList}
        />
      </Col>
    </Row>
  )
}

EmptyCollaboratorList.propTypes = {
  handleAddCollaborator: propTypes.func.isRequired,
  handleAddCollaboratorList: propTypes.func.isRequired,
  noSearchResults: propTypes.bool.isRequired,
}
export default EmptyCollaboratorList
