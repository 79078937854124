import React, { useEffect, useState } from 'react'
import MediaQuery from 'react-responsive'
import omit from 'lodash/omit'
import difference from 'lodash/difference'
import propTypes from 'prop-types'

import DesktopCollaboratorList from './desktop-collaborator-list'
import MobileCollaboratorList from './mobile-collaborator-list'
import AddCollaboratorModal from './add-collaborator-modal'
import AddCollaboratorListErrorModal from './AddCollaboratorListErrorModal'
import DeleteCollaboratorModal from './DeleteCollaboratorModal'
import DownloadAllPicturesModal from './DownloadAllPicturesModal'
import { renderDate } from '../../../../helpers'

const CollaboratorList = ({
  project,
  collaborators,
  isLoading,
  navigate,
  importCollaborators,
  importCollaborator,
  deleteCollaborator,
  downloadAllPictures,
}) => {
  // account-manager
  const [collaboratorList, setList] = useState([])
  const [collaboratorSearchList, setSearchList] = useState([])
  const [noSearchResults, setSearchStatus] = useState(false)
  const [nbCollabToShow, setNbCollab] = useState(12)
  const [isMore, setMore] = useState(false)
  const [sortHistory, setSortHistory] = useState({
    sortFilter: '',
    sortType: '',
  })

  useEffect(() => {
    if (typeof collaborators !== 'undefined') {
      setList(collaborators.slice(0, nbCollabToShow))
      setSearchList(collaborators.slice(0, nbCollabToShow))
      setMore(collaborators.length > nbCollabToShow)
    }
  }, [collaborators, nbCollabToShow])

  const [showAddCollabModal, setShowAddCollabModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showDownloadModal, toggleDownloadModal] = useState(false)
  const [error, setError] = useState(null)

  // import collaborator from csv file
  const addCollaboratorList = event => {
    const file = event.target.files[0]
    const fileReader = new FileReader()
    fileReader.onload = async () => {
      const str = String(fileReader.result)
      try {
        // reading file
        const res = await importCollaborators(str, project.id)
        if (res !== true) {
          setError(res)
          setShowErrorModal(true)
        }
      } catch (e) {
        setError(e.message)
        setShowErrorModal(true)
      }
    }
    fileReader.readAsText(file)
  }

  const addCollaborator = async collaborator => {
    // format date
    let newCollaborator = omit(collaborator, ['day', 'month', 'year'])
    newCollaborator = {
      ...newCollaborator,
      date: `${collaborator.day}-${collaborator.month}-${collaborator.year}`,
    }
    try {
      return await importCollaborator(newCollaborator, project.id)
    } catch (e) {
      return false
    }
  }
  // todo notify collaborator

  const [selectedList, setSelectedList] = useState([])
  const [showDeleteCollabModal, setShowDeleteCollabModal] = useState(false)

  const handleDelete = () => {
    selectedList.forEach(collaborator => {
      deleteCollaborator(collaborator)
    })
    const newList = difference(collaboratorList, selectedList)
    setList(newList)
    setSelectedList([])
    setShowDeleteCollabModal(false)
  }

  const [isAllChecked, checkAll] = useState(false)

  useEffect(() => {
    checkAll(selectedList.length === collaborators.length)
  }, [selectedList, collaborators])

  const deselectAll = () => {
    setSelectedList([])
  }
  const handleSelectAll = () => {
    if (!isAllChecked) setSelectedList([...collaborators])
    else deselectAll()
  }

  const handleSelect = collaborator => {
    if (selectedList.findIndex(c => c.id === collaborator.id) > -1)
      setSelectedList(selectedList.filter(c => c.id !== collaborator.id))
    else setSelectedList([...selectedList, collaborator])
  }

  const handleDownloadAllPictures = (list, currentProject) => {
    downloadAllPictures(list, currentProject)
    toggleDownloadModal(true)
  }
  const handleSearch = value => {
    const searchValue = value.toUpperCase()
    let searchArray = [...collaborators]

    if (value.length !== 0 && searchArray.length) {
      searchArray = searchArray.filter(({ firstname, lastname, pivot }) => {
        const date = pivot.session_date
          ? pivot.session_date.toUpperCase()
          : 'Date à définir'
        const presence = pivot.presence
          ? pivot.presence.toUpperCase()
          : 'not confirmed'.toUpperCase()

        return (
          lastname.toUpperCase().indexOf(searchValue) > -1 ||
          firstname.toUpperCase().indexOf(searchValue) > -1 ||
          date.toUpperCase().indexOf(searchValue) > -1 ||
          presence.toUpperCase().indexOf(searchValue) > -1
        )
      })
      setList(searchArray)
      setSearchStatus(true)
    } else {
      setSearchStatus(false)
      setList(collaboratorSearchList)
    }
  }

  const isChecked = collaborator =>
    selectedList.findIndex(c => c.id === collaborator.id) !== -1

  const sort = (sortFilter, sortType) => {
    const sortArray = [...collaboratorList]

    setSortHistory({ sortFilter, sortType })

    if (sortFilter && sortType) {
      setList(
        sortArray.sort((a, b) => {
          let itemA = ''
          let itemB = ''
          let result = 0

          switch (sortFilter) {
            case 'session_date':
              itemA = a.pivot.session_date
                ? a.pivot.session_date
                : 'Date à définir'
              itemB = b.pivot.session_date
                ? b.pivot.session_date
                : 'Date à définir'
              break
            case 'presence':
              itemA = a.pivot.presence ? a.pivot.presence : ''
              itemB = b.pivot.presence ? b.pivot.presence : ''
              break
            case 'statut':
              itemA = a.pivot.user_project_status
                ? a.pivot.user_project_status
                : 'En cours'
              itemB = b.pivot.user_project_status
                ? b.pivot.user_project_status
                : 'En cours'
              break
            default:
              itemA = a[sortFilter]
              itemB = b[sortFilter]
              break
          }

          if (itemA.toUpperCase() > itemB.toUpperCase()) {
            result = 1
          } else if (itemA.toUpperCase() < itemB.toUpperCase()) {
            result = -1
          }

          return sortType === 'desc' ? result * -1 : result
        })
      )
    }
  }

  const showMore = () => {
    setNbCollab(prev => prev + 12)
    setTimeout(() => {
      sort(sortHistory.sortFilter, sortHistory.sortType)
    })
  }

  const listProps = {
    isLoading,
    project,
    collaborators: collaboratorList,
    navigate,
    handleSelect,
    deselectAll,
    selectedList,
    isChecked,
    showAddCollabModal: setShowAddCollabModal,
    showDeleteCollabModal: setShowDeleteCollabModal,
    showMore,
    isMore,
    importCollaborators: addCollaboratorList,
    handleSearch,
    noSearchResults,
    renderDate,
  }

  return (
    <>
      <MediaQuery minWidth={992}>
        <DesktopCollaboratorList
          {...listProps}
          sort={sort}
          selectAll={handleSelectAll}
          downloadAllPictures={handleDownloadAllPictures}
        />
      </MediaQuery>
      <MediaQuery maxWidth={991}>
        <MobileCollaboratorList {...listProps} />
      </MediaQuery>
      <DeleteCollaboratorModal
        selectedList={selectedList}
        handleDelete={handleDelete}
        show={showDeleteCollabModal}
        setShow={setShowDeleteCollabModal}
      />
      <AddCollaboratorModal
        show={showAddCollabModal}
        setShow={setShowAddCollabModal}
        handleSubmit={addCollaborator}
        project={project}
      />
      <AddCollaboratorListErrorModal
        show={showErrorModal}
        setShow={setShowErrorModal}
        error={error}
      />
      <DownloadAllPicturesModal
        show={showDownloadModal}
        setShow={toggleDownloadModal}
      />
    </>
  )
}

CollaboratorList.propTypes = {
  project: propTypes.objectOf(propTypes.any).isRequired,
  isLoading: propTypes.bool.isRequired,
  collaborators: propTypes.arrayOf(propTypes.object),
  navigate: propTypes.func.isRequired,
  importCollaborators: propTypes.func.isRequired,
  importCollaborator: propTypes.func.isRequired,
  deleteCollaborator: propTypes.func.isRequired,
  downloadAllPictures: propTypes.func.isRequired,
}
CollaboratorList.defaultProps = {
  collaborators: [],
}
export default CollaboratorList
