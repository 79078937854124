/* eslint-disable react/no-danger */
import React from 'react'
import Card from 'react-bootstrap/Card'
import propTypes from 'prop-types'

import './styles.scss'
import StatusBadge from '../../app-components/status-badge'
import { formatDate } from '../../../helpers'

const ProjectListItem = ({ project, isCollaborator }) => {
  const renderDate = () => {
    if (isCollaborator && project.is_booking_studio_collaborator === 1) {
      if (project.pivot.session_date !== null)
        return formatDate(project.pivot.session_date)
      return 'Date à définir'
    }
    if (project.session_date !== null) {
      return formatDate(project.session_date)
    }

    return 'N/C'
  }
  return (
    <Card className="project-list-item mb-3 pb-2">
      <Card.Img
        className="project-list-item-image img-squared"
        variant="top"
        src={project.image}
      />
      <Card.Body className="d-flex flex-column justify-content-between p-0">
        <div className="my-2 mx-3">
          <Card.Title className="project-list-item-title">
            {project.name}
          </Card.Title>
          {project.description !== null && (
            <Card.Text
              className="project-list-item-text mb-lg-1"
              dangerouslySetInnerHTML={{
                __html: `${project.description.substring(0, 100)}${
                  project.description.length > 100 ? '...' : ''
                }`,
              }}
            />
          )}
        </div>
        <div className="d-flex justify-content-between mb-2 my-lg-2 mx-3">
          <span className="project-list-item-date">{renderDate()}</span>
          <StatusBadge
            status={
              isCollaborator
                ? project.pivot.user_project_status
                : project.status
            }
            badgeClass="project-list-item-status"
          />
        </div>
      </Card.Body>
    </Card>
  )
}
ProjectListItem.propTypes = {
  project: propTypes.objectOf(propTypes.any).isRequired,
  isCollaborator: propTypes.bool.isRequired,
}

export default ProjectListItem
