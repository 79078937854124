import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { Button, Col, Row, Badge } from 'react-bootstrap'
import { connect } from 'react-redux'
import Icon from '../../../app-components/icon'
import { COLLABORATOR_GALLERY_STEPS as STEPS } from '../../../../constants/steps'

import {
  doGetReportageAnimationPictures,
  doDownloadAllReportageAnimationPictures,
} from '../../../../store/projects/actions'
import { getPhotosFromProject } from '../../../../store/projects/selectors'

import LoadingIndicator from '../../../app-components/loading-indicator'
import EmptyPhotoSet from '../photo-gallery/empty-photoset'
import DownloadAllPicturesModal from './DownloadAllPicturesModal'

const PhotoList = ({
  project,
  photos,
  isLoading,
  getPhotos,
  downloadReportageAnimation,
}) => {
  let n = 1
  const [photoLimit, setPhotoLimit] = useState(16)
  const [photoList, setPhotoList] = useState([])
  const updateLimit = () => setPhotoLimit(photoLimit + 16)

  useEffect(() => {
    getPhotos(project)
  }, [])

  const [showDownloadModal, toggleDownloadModal] = useState(false)
  const handleDownloadReportageAnimation = p => {
    downloadReportageAnimation(p)
    toggleDownloadModal(!showDownloadModal)
  }

  useEffect(() => {
    if (Array.isArray(photos)) setPhotoList(photos.slice(0, photoLimit))
  }, [photoLimit, photos])

  return (
    <div className="project-photolist container">
      <div className="project-download">
        <span>
          {photos.length !== 0
            ? 'Vous pouvez dès maintenant'
            : 'Vous ne pouvez pas encore'}{' '}
          <strong>télécharger toutes vos photos.</strong>
        </span>
        <Badge
          as={Button}
          id="download-all-photolist-btn"
          className={`${photos.length !== 0 ? '' : 'disabled'}`}
          onClick={() => {
            if (photos.length !== 0) handleDownloadReportageAnimation(project)
          }}
          pill
        >
          <strong className="header-action-text">Télécharger</strong>
          <Icon
            iconClass="text-primary font-weight-bold"
            name="icon-download"
          />
        </Badge>
      </div>
      <hr className="project-photolist-separator" />
      {photoList.length > 0 ? (
        <>
          {photoList.map(photo => {
            const url = photo.final_url ? photo.final_url : photo.url
            const name = `${project.name} - ${n}`
            n += 1
            return (
              <img
                className="project-photolist-picture"
                key={n}
                alt={name}
                title={name}
                src={url}
              />
            )
          })}
          <Row className="justify-content-center">
            <Col className="text-center" md="auto">
              <Button
                variant="primary"
                disabled={photoList.length === photos.length}
                size="small"
                onClick={updateLimit}
              >
                PLUS DE PHOTOS
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <EmptyPhotoSet step={STEPS.SELECT} />
      )}
      <DownloadAllPicturesModal
        show={showDownloadModal}
        setShow={toggleDownloadModal}
      />
      {isLoading && <LoadingIndicator />}
    </div>
  )
}
PhotoList.propTypes = {
  project: propTypes.objectOf(propTypes.any).isRequired,
  isLoading: propTypes.bool.isRequired,
  getPhotos: propTypes.func.isRequired,
  downloadReportageAnimation: propTypes.func.isRequired,
  photos: propTypes.objectOf(propTypes.object).isRequired,
}

const mapStateToProps = (state, props) => ({
  photos: getPhotosFromProject(state, props),
})

const mapDispatchToProps = dispatch => ({
  getPhotos: project => dispatch(doGetReportageAnimationPictures(project)),
  downloadReportageAnimation: project =>
    dispatch(doDownloadAllReportageAnimationPictures(project)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PhotoList)
