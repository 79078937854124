export const PICTURE_STATUS = {
  PREVIEW: 'preview',
  SELECTED: 'selected',
  FINAL: 'final',
}

export const TRANSITION_TYPE = {
  SELECT: 'select_picture',
}

export const PROJECT_STATUS = {
  PENDING_PREVIEW_PICTURES: 'pending_preview_pictures',
  PENDING_PICTURES_SELECTIONS: 'pending_pictures_selections',
  PENDING_FINAL_PICTURES: 'pending_final_pictures',
  FINISHED: 'finished',
}
