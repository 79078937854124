import React from 'react'
import propTypes from 'prop-types'
import ModalCard from '../../../app-components/modal-card'
import Icon from '../../../app-components/icon'

const DownloadAllPicturesModal = ({ show, setShow }) => {
  return (
    <ModalCard
      icon={<Icon name="icon-download" iconClass="card-body-icon" />}
      title={
        <p>
          <strong>Votre téléchargement sera bientôt disponible. </strong>
          Vous allez recevoir le lien par e-mail.
        </p>
      }
      show={show}
      onHide={() => setShow(false)}
      heading="Télécharger"
    />
  )
}
DownloadAllPicturesModal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
}
export default DownloadAllPicturesModal
