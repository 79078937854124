import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row, Table, Container, Badge } from 'react-bootstrap'
import propTypes from 'prop-types'
import Icon from '../../../../app-components/icon'
import StatusBadge from '../../../../app-components/status-badge'
import Checkbox from '../../../../app-components/checkbox'
import './styles.scss'
import EmptyCollaboratorList from '../empty-collaborator-list'
import LoadingIndicator from '../../../../app-components/loading-indicator'
import { slug } from '../../../../../helpers'
import { PROJECT_STATUS } from '../../../../../constants/status'

const DesktopCollaboratorList = ({
  project,
  collaborators,
  isLoading,
  navigate,
  isAllChecked,
  isChecked,
  handleSelect,
  selectedList,
  showAddCollabModal,
  showDeleteCollabModal,
  showMore,
  isMore,
  selectAll,
  importCollaborators,
  sort,
  handleSearch,
  noSearchResults,
  downloadAllPictures,
  renderDate,
}) => {
  const importCsv = useRef(null)
  const [sortFilter, setSortFilter] = useState({ name: '', type: '' })
  const setSortType = type =>
    sortFilter.name === type && sortFilter.type === 'asc' ? 'desc' : 'asc'

  useEffect(() => {
    sort(sortFilter.name, sortFilter.type)
  }, [sortFilter])

  return (
    <>
      <Container className="desktop-collaborator-menu">
        <Row
          className={`collaborator-list-menu justify-content-between align-items-center mx-0 ${
            selectedList.length > 0 ? 'selected' : ''
          }`}
        >
          <Col xs="auto" sm={6}>
            {selectedList.length > 0 ? (
              <span className="text-uppercase font-weight-bold header-action-text">
                {selectedList.length}
                {selectedList.length > 1
                  ? ' COLLABORATEURS SÉLECTIONNÉS'
                  : ' COLLABORATEUR SÉLECTIONNÉ'}
              </span>
            ) : (
              <div className="collaborator-search-wrapper">
                <Button
                  variant="flat"
                  size="lg"
                  className="collaborator-search-button"
                >
                  <Icon name="icon-search" />
                </Button>
                <input
                  type="text"
                  className="collaborator-search"
                  onChange={e => handleSearch(e.target.value)}
                />
              </div>
            )}
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            {selectedList.length === 0 && (
              <Button
                variant="flat"
                size="lg"
                className="d-none d-lg-block m-0 import-btn"
                onClick={() => {
                  importCsv.current.value = null
                  importCsv.current.click()
                }}
              >
                <span className="import-btn-text">Importer CSV</span>
                <Icon name="icon-import-csv" iconClass="text-primary" />
                <input
                  id="import-csv"
                  ref={importCsv}
                  className="d-none"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={importCollaborators}
                />
              </Button>
            )}
            {collaborators.length > 0 && (
              <>
                {selectedList.length === 0 && (
                  <Button
                    variant="flat"
                    size="lg"
                    onClick={() => showAddCollabModal(true)}
                  >
                    <Icon
                      name="icon-create-collaborator"
                      iconClass="text-primary"
                    />
                  </Button>
                )}
                {selectedList.length > 0 ? (
                  <Badge
                    as={Button}
                    className="download-btn"
                    onClick={() => downloadAllPictures(selectedList, project)}
                    pill
                  >
                    <strong className="header-action-text">Télécharger</strong>
                    <Icon
                      iconClass="text-primary font-weight-bold"
                      name="icon-download"
                    />
                  </Badge>
                ) : (
                  <Button
                    className={`header-action-btn ${
                      project.status === PROJECT_STATUS.FINISHED ? 'active' : ''
                    }`}
                    variant="flat"
                    size="lg"
                    onClick={() => downloadAllPictures([], project)}
                  >
                    <strong className="header-action-text">Télécharger</strong>
                    <Icon name="icon-download" />
                  </Button>
                )}
                {selectedList.length > 0 && (
                  <Button
                    className="header-action-btn delete-btn"
                    variant="flat"
                    size="lg"
                    onClick={() => showDeleteCollabModal(true)}
                  >
                    <Icon
                      iconClass="text-primary delete-icon"
                      name="icon-delete"
                    />
                  </Button>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container className="desktop-collaborator-list">
        <Table>
          <thead className="desktop-collaborator-list-nav justify-content-around">
            <tr className="py-3 border-0">
              <th
                className={`desktop-collaborator-list-nav-item text-left ${
                  sortFilter.name === 'lastname' ? sortFilter.type : ''
                }`}
                onClick={() => {
                  setSortFilter({
                    name: 'lastname',
                    type: setSortType('lastname'),
                  })
                }}
              >
                {collaborators.length > 0 && (
                  <Checkbox
                    id="checkAll"
                    className="collaborator-list-checkbox"
                    isChecked={isAllChecked}
                    onChange={selectAll}
                    onClick={e => e.stopPropagation()}
                  />
                )}{' '}
                Nom <span className="icon-arrow" />
              </th>
              <th
                className={`desktop-collaborator-list-nav-item ${
                  sortFilter.name === 'firstname' ? sortFilter.type : ''
                }`}
                onClick={() => {
                  setSortFilter({
                    name: 'firstname',
                    type: setSortType('firstname'),
                  })
                }}
              >
                {' '}
                Prénom <span className="icon-arrow" />
              </th>
              <th
                className={`desktop-collaborator-list-nav-item ${
                  sortFilter.name === 'session_date' ? sortFilter.type : ''
                }`}
                onClick={() => {
                  setSortFilter({
                    name: 'session_date',
                    type: setSortType('session_date'),
                  })
                }}
              >
                {' '}
                Date de séance <span className="icon-arrow" />
              </th>
              <th
                className={`desktop-collaborator-list-nav-item ${
                  sortFilter.name === 'presence' ? sortFilter.type : ''
                }`}
                onClick={() => {
                  setSortFilter({
                    name: 'presence',
                    type: setSortType('presence'),
                  })
                }}
              >
                {' '}
                Présence <span className="icon-arrow" />
              </th>
              <th
                className={`desktop-collaborator-list-nav-item ${
                  sortFilter.name === 'statut' ? sortFilter.type : ''
                }`}
                onClick={() => {
                  setSortFilter({
                    name: 'statut',
                    type: setSortType('statut'),
                  })
                }}
              >
                {' '}
                Statut <Icon name="icon-arrow" />
              </th>
            </tr>
          </thead>
          {/* Collaborator list */}
          {collaborators && (
            <tbody>
              {collaborators.map((collab, index) => (
                <tr
                  className={`desktop-collaborator-list-item ${
                    isChecked(collab) ? 'selected' : ''
                  }`}
                  key={collab.id}
                >
                  <td>
                    <Button
                      className="desktop-collaborator-list-item-edit-btn"
                      variant="flat"
                      size="lg"
                      onClick={() =>
                        navigate(slug(`${collab.lastname}-${collab.firstname}`))
                      }
                    >
                      <Icon name="icon-edit-pen" paths={1} />
                    </Button>
                    <Checkbox
                      id={`check-${index}`}
                      onChange={() => {
                        handleSelect(collab)
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        e.target.classList.toggle('selected')
                      }}
                      isChecked={isChecked(collab)}
                      className="collaborator-list-checkbox"
                      square
                    />{' '}
                    {collab.lastname}
                  </td>
                  <td>{collab.firstname}</td>
                  <td>{renderDate(project, collab)}</td>
                  <td>
                    {collab.pivot.presence === 'confirmed'
                      ? 'Confirmée'
                      : 'Non confirmée'}
                  </td>
                  <td>
                    <StatusBadge status={collab.pivot.user_project_status} />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {isLoading && <LoadingIndicator />}
        {collaborators.length === 0 && (
          <EmptyCollaboratorList
            handleAddCollaborator={showAddCollabModal}
            handleAddCollaboratorList={importCollaborators}
            noSearchResults={noSearchResults}
          />
        )}
        {/* Todo handle pagination */}
        {collaborators.length > 0 && (
          <Row className="justify-content-center">
            <Col md="auto">
              <Button
                variant="primary"
                disabled={!isMore}
                size="small"
                onClick={showMore}
              >
                VOIR PLUS DE COLLABORATEURS
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}
DesktopCollaboratorList.propTypes = {
  project: propTypes.objectOf(propTypes.any).isRequired,
  collaborators: propTypes.arrayOf(propTypes.object).isRequired,
  isLoading: propTypes.bool.isRequired,
  navigate: propTypes.func.isRequired,
  isAllChecked: propTypes.bool.isRequired,
  isChecked: propTypes.func.isRequired,
  handleSelect: propTypes.func.isRequired,
  selectAll: propTypes.func.isRequired,
  showMore: propTypes.func.isRequired,
  isMore: propTypes.bool.isRequired,
  selectedList: propTypes.arrayOf(propTypes.object).isRequired,
  showAddCollabModal: propTypes.func.isRequired,
  showDeleteCollabModal: propTypes.func.isRequired,
  importCollaborators: propTypes.func.isRequired,
  sort: propTypes.func.isRequired,
  handleSearch: propTypes.func.isRequired,
  noSearchResults: propTypes.bool.isRequired,
  downloadAllPictures: propTypes.func.isRequired,
  renderDate: propTypes.func.isRequired,
}
export default DesktopCollaboratorList
