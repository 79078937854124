import React from 'react'
import propTypes from 'prop-types'
import ImageOverlay from '../../../app-components/image-overlay'
import Checkbox from '../../../app-components/checkbox'
import AdditionalPicture from '../../../app-components/additional-picture'
import AdditionalPictureBanner from '../../../app-components/additional-picture-banner'
import Icon from '../../../app-components/icon'
import { COLLABORATOR_GALLERY_STEPS as STEPS } from '../../../../constants/steps'
import { USER_GROUP } from '../../../../constants/user'

const detailView = (
  <div className="overlay hover gallery-detail-view">
    <Icon name="icon-zoom" iconClass="text-white" />
  </div>
)

const GalleryItem = ({
  item,
  index,
  isChecked,
  setModalShow,
  setModalAddPicShow,
  setAdditionalPicture,
  setInitialSlide,
  handleSelection,
  selectionDone,
  step,
  selectedAdditionalPictures,
  product,
  user,
}) => {
  return (
    <>
      <div
        onClick={() => {
          setInitialSlide(index)
          setModalShow()
        }}
        className={`${
          !isChecked && selectionDone ? 'disabled-item' : ''
        } position-relative`}
      >
        <ImageOverlay
          className={`overlay-hover ${
            isChecked && step !== STEPS.CONFIRM ? 'selected' : ''
          } `}
          key={item.id}
          img={item.url}
          detailView={detailView}
        />
        {isChecked &&
          selectionDone &&
          selectedAdditionalPictures.findIndex(i => i.id === item.id) >= 0 && (
            <AdditionalPictureBanner />
          )}
      </div>
      {!isChecked &&
        selectionDone &&
        user !== null &&
        // 12.08.2022 Enable Additional picture for B2B and B2C
        (user.type === USER_GROUP.CLIENT ||
          user.type === USER_GROUP.COLLABORATOR) && (
          <AdditionalPicture
            className="gallery-additional-picture"
            onClick={() => {
              setModalAddPicShow(true)
              setAdditionalPicture(item)
            }}
            montant={product?.price}
          />
        )}
      <Checkbox
        onChange={e => {
          return parseInt(step, 10) === STEPS.SELECT
            ? handleSelection(e, index, item)
            : false
        }}
        isChecked={isChecked}
        className={`gallery-checkbox additional-picture ${
          !isChecked && selectionDone ? 'd-none' : ''
        }`}
        square={false}
      />
    </>
  )
}
GalleryItem.propTypes = {
  item: propTypes.objectOf(propTypes.any).isRequired,
  index: propTypes.number.isRequired,
  isChecked: propTypes.bool.isRequired,
  setModalShow: propTypes.func.isRequired,
  setModalAddPicShow: propTypes.func.isRequired,
  setInitialSlide: propTypes.func.isRequired,
  handleSelection: propTypes.func.isRequired,
  selectionDone: propTypes.bool.isRequired,
  step: propTypes.number.isRequired,
  setAdditionalPicture: propTypes.func.isRequired,
  selectedAdditionalPictures: propTypes.arrayOf(propTypes.any).isRequired,
  product: propTypes.objectOf(propTypes.any),
  user: propTypes.objectOf(propTypes.any).isRequired,
}

GalleryItem.defaultProps = { product: null }
export default GalleryItem
