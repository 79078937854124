import React from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import propTypes from 'prop-types'

import Icon from '../../../app-components/icon'

const RetouchedSet = ({ pictures, handleDownload, handleDownloadAll }) => (
  <>
    <Row className="gallery-heading my-4 mb-lg-5 mx-0">
      Vos photos retouchées sont maintenant disponibles,&nbsp;
      <strong>vous pouvez les télécharger.</strong>
    </Row>
    <Row className="mx-2 photos-row">
      {pictures.map((item, index) => (
        <Col className="photos-col px-2 pb-3" xs={6} md={3} key={item.id}>
          <div className="final-images">
            <img
              src={item.final_url}
              alt={`Retouchée ${item.id}`}
              className="img-fluid"
            />
            <Button
              variant="flat"
              className="final-download-icon"
              onClick={() => handleDownload(item, index)}
            >
              <Icon name="icon-download" paths={3} />
            </Button>
          </div>
        </Col>
      ))}
    </Row>
    <Row className="justify-content-center mx-0">
      <Button
        variant="secondary"
        size="lg"
        className="my-4"
        onClick={handleDownloadAll}
      >
        TÉLÉCHARGER MA SELECTION
      </Button>
    </Row>
  </>
)
RetouchedSet.propTypes = {
  pictures: propTypes.arrayOf(propTypes.any).isRequired,
  handleDownload: propTypes.func.isRequired,
  handleDownloadAll: propTypes.func.isRequired,
}
export default RetouchedSet
