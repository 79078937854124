import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import MediaQuery from 'react-responsive'

import { useLocation } from '@reach/router'
import PhotoGallery from '../photo-gallery'
import Heading from '../../../app-components/dashed-heading'
import LoadingIndicator from '../../../app-components/loading-indicator'
import GalleryHeader from '../gallery-header'
import { getProjectBySlug } from '../../../../store/projects/selectors'
import {
  getIsClient,
  getUser,
} from '../../../../store/authentication/selectors'
import {
  doDownloadAllPictures,
  doDownloadPicture,
  doGetCollaboratorPictures,
  doSelectPicture,
} from '../../../../store/collaborators/actions'
import { doDownloadAllPictures as doDownloadAllProjectPictures } from '../../../../store/projects/actions'
import { getUserPictures } from '../../../../store/collaborators/selectors'

import './styles.scss'
import DownloadAllPicturesModal from '../account-manager/DownloadAllPicturesModal'

const CollaboratorProjectDetail = ({
  project,
  user,
  getPictures,
  pictures,
  selectPicture,
  downloadPicture,
  downloadAllPictures,
  downloadAllProjectPictures,
  navigate,
  isLoading,
  isClient,
}) => {
  const location = useLocation()
  const [showDownloadModal, toggleDownloadModal] = useState(false)

  useEffect(() => {
    async function init() {
      await getPictures(project, user, project.pivot.user_project_status)
    }

    init()
  }, [location.pathname, getPictures, project])

  const downloadAll = (collaborator, dProject) => {
    // B2C project w/ many files to download
    if (isClient && pictures.length > 10) {
      toggleDownloadModal(true)
      return downloadAllProjectPictures(dProject)
    }
    return downloadAllPictures(collaborator, dProject)
  }
  return (
    <>
      <Container>
        <Heading className="mt-5 mb-4" title={project.name} />
        <MediaQuery minWidth={992}>
          <GalleryHeader collaborator={user} />
        </MediaQuery>
      </Container>
      <PhotoGallery
        project={project}
        user={user}
        pictures={pictures}
        selectPicture={selectPicture}
        navigate={navigate}
        downloadAllPictures={downloadAll}
        downloadPicture={downloadPicture}
      />
      {isLoading && <LoadingIndicator />}
      <DownloadAllPicturesModal
        show={showDownloadModal}
        setShow={toggleDownloadModal}
      />
    </>
  )
}

CollaboratorProjectDetail.propTypes = {
  project: propTypes.objectOf(propTypes.any).isRequired,
  user: propTypes.objectOf(propTypes.any).isRequired,
  getPictures: propTypes.func.isRequired,
  pictures: propTypes.arrayOf(propTypes.object).isRequired,
  selectPicture: propTypes.func.isRequired,
  downloadPicture: propTypes.func.isRequired,
  downloadAllPictures: propTypes.func.isRequired,
  downloadAllProjectPictures: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  navigate: propTypes.func.isRequired,
  isClient: propTypes.bool.isRequired,
}

const mapStateToProps = (state, props) => ({
  user: getUser(state),
  project: getProjectBySlug(state, props),
  pictures: getUserPictures(state, props),
  isLoading: state.collaborator.isLoading,
  isClient: getIsClient(state),
})

const mapDispatchToProps = dispatch => ({
  getPictures: (project, collaborator, type) =>
    dispatch(doGetCollaboratorPictures(project, collaborator, type)),
  selectPicture: (picture, transition) =>
    dispatch(doSelectPicture(picture, transition)),
  downloadPicture: (collaborator, picture, index) =>
    dispatch(doDownloadPicture(collaborator, picture, index)),
  downloadAllPictures: (collaborator, project) =>
    dispatch(doDownloadAllPictures(collaborator, project)),
  downloadAllProjectPictures: project =>
    dispatch(doDownloadAllProjectPictures([], project)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaboratorProjectDetail)
