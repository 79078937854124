import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router'
import { Col, Container, Row, Tab } from 'react-bootstrap'
import { graphql, Link } from 'gatsby'
import { unslugify } from 'unslugify'

import { connect } from 'react-redux'
import propTypes from 'prop-types'
import Layout from '../../layouts/default'
import Breadcrumbs from '../../components/app-components/breadcrumbs'
import Banner from '../../components/sections/_banner/default'
import AccountForm from '../../components/app-components/account-form'
import ProjectList from '../../components/back-office/project-list'
import AccountManagerProjectDetail from '../../components/back-office/project-detail/account-manager'
import ManagerCollaboratorDetailView from '../../components/back-office/project-detail/account-manager/CollaboratorDetailView'
import ChangePasswordForm from '../../components/app-components/change-password-form'
import DashedHeading from '../../components/app-components/dashed-heading'
import BackOfficeMenu from '../../components/app-components/back-office-menu'
import CollaboratorProjectDetail from '../../components/back-office/project-detail/collaborator'
import {
  doChangePassword,
  doUpdateAccount,
} from '../../store/authentication/actions'

import {
  getIsAccountManager,
  getIsClient,
  getIsCollaborator,
  getUser,
} from '../../store/authentication/selectors'

import proBannerImage from '../../assets/images/compte-pro-banner.png'
import bannerImage from '../../assets/images/header-product-page.jpg'

import './styles.scss'

import Icon from '../../components/app-components/icon'
import SessionBooking from '../../components/back-office/session-booking'
import PrivateRoute from '../../components/app-components/private-route'

const UserAccountTemplate = ({
  location,
  user,
  isCollaborator,
  isAccountManager,
  isClient,
  changePassword,
  updateAccount,
  data: {
    wpPage: { seo },
  },
}) => {
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false)
  useEffect(() => {
    setChangePasswordSuccess(false)
  }, [location])

  const bannerTitle = () => {
    if (isAccountManager) return user.companyname
    return 'Mon Compte'
  }
  const handleNav = () => {
    if (location.pathname.includes('mot-de-passe')) return 'password'
    if (location.pathname.includes('informations')) return 'informations'
    return 'projects'
  }

  const handleChangePassword = async data => {
    const res = await changePassword(data)
    if (res === true) {
      setChangePasswordSuccess(true)
    }
  }

  // return a route array for each path in URL
  const getRoutes = () => {
    const loc = location.pathname.split('/')
    // delete first occurence
    loc.shift()
    const breadcrumbs = []
    for (let i = 0; i < loc.length; i += 1) {
      let title = ''
      let path = `/${loc[i]}`
      if (i === 0) {
        title = 'Mon Compte'
      }
      if (i === 1) {
        switch (loc[i]) {
          case 'projets':
            title = 'Mes projets'
            break
          default:
            title = unslugify(loc[i])
        }
        path = `/compte/${loc[i]}`
      }
      if (i === 2) {
        title = unslugify(loc[i])
        path = `/compte/projets/${loc[i]}`
      }
      if (i === 3 && !isCollaborator) {
        title = unslugify(loc[i])
        path = `/compte/projets/${loc[i - 1]}/${loc[i]}`
      }
      if ((!isCollaborator && i === 4) || i === 3) {
        switch (loc[i]) {
          case 'preview':
            title = 'Aperçu photo'
            break
          default:
            title = unslugify(loc[i])
        }
        path = ''
      }
      // Avoid empty steps
      if (title !== '') breadcrumbs.push({ title, path })
    }

    return breadcrumbs
  }

  const renderPage = (
    <Layout seo={seo}>
      <Banner
        title={bannerTitle()}
        bannerClass="login-banner"
        backgroundImg={isCollaborator ? bannerImage : proBannerImage}
      />
      <div className="page-compte">
        <Container className="mt-3">
          <Breadcrumbs route={getRoutes()} />
        </Container>
        <Tab.Container
          defaultActiveKey="projects"
          activeKey={handleNav()}
          id="compte-tab-nav"
        >
          {/* Pill menu */}
          <BackOfficeMenu isAccountManager={isAccountManager} />
          <Tab.Content>
            {/* Project tab */}
            <Tab.Pane eventKey="projects">
              <Router basepath="/compte/projets">
                <ProjectList default />
                {(isCollaborator || isClient) && (
                  <CollaboratorProjectDetail path="/:projectSlug/*" />
                )}
                {isAccountManager && (
                  <AccountManagerProjectDetail path="/:projectSlug" />
                )}
                <SessionBooking path="/:projectSlug/reservation/*" />
                {isAccountManager && (
                  <ManagerCollaboratorDetailView path="/:projectSlug/:collaboratorSlug/*" />
                )}
              </Router>
            </Tab.Pane>

            {/* Informations tab */}
            <Tab.Pane eventKey="informations">
              {user !== null && (
                <AccountForm
                  handleSubmit={updateAccount}
                  user={user}
                  isCollaborator={isCollaborator}
                  isClient={isClient}
                  isAccountManager={isAccountManager}
                />
              )}
            </Tab.Pane>

            {/* Password tab */}
            <Tab.Pane eventKey="password">
              <Container>
                <DashedHeading title="Modifier votre mot de passe" />
                {changePasswordSuccess ? (
                  <>
                    <Row>
                      <Col className="text-center">
                        <Icon
                          name="icon-password"
                          iconClass="display-3"
                          paths={10}
                        />
                        <p className="form-dec pt-2 pb-4">
                          Votre demande de{' '}
                          <strong>changement de mot de passe</strong> a été
                          prise en compte.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center">
                        <Link
                          className="btn btn-lg btn-secondary"
                          to="/compte/projets"
                        >
                          Retour à mes projets
                        </Link>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <ChangePasswordForm handleSubmit={handleChangePassword} />
                )}
              </Container>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Layout>
  )
  return <PrivateRoute element={renderPage} location={location} />
}

export const query = graphql`
  query UserAccountPage($id: String!) {
    wpPage(id: { eq: $id }) {
      slug
      template{
        templateName
      }
      title
      id
      ...yoastMeta
    }
  }
`

UserAccountTemplate.propTypes = {
  location: propTypes.objectOf(propTypes.any).isRequired,
  user: propTypes.objectOf(propTypes.any).isRequired,
  isCollaborator: propTypes.bool.isRequired,
  isAccountManager: propTypes.bool.isRequired,
  isClient: propTypes.bool.isRequired,
  changePassword: propTypes.func.isRequired,
  updateAccount: propTypes.func.isRequired,
  data: propTypes.objectOf(propTypes.any).isRequired,
}

const mapStateToProps = state => ({
  user: getUser(state),
  isCollaborator: getIsCollaborator(state),
  isAccountManager: getIsAccountManager(state),
  isClient: getIsClient(state),
})

const mapDispatchToProps = dispatch => ({
  changePassword: data => dispatch(doChangePassword(data)),
  updateAccount: data => dispatch(doUpdateAccount(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountTemplate)
