import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import propTypes from 'prop-types'
import { Router } from '@reach/router'

import moment from 'moment'
import 'moment/locale/fr'
import { connect } from 'react-redux'
import Link from '../../../app-components/link'
import ModalCard from '../../../app-components/modal-card'
import Icon from '../../../app-components/icon'
import GallerySliderModal from './gallery-modal'
import PictureOptionsModal from './picture-options-modal'
import FixedBottomButton from '../../../app-components/fixed-bottom-button'
import GalleryItem from './GalleryItem'
import RetouchedSet from './RetouchedSet'

import { COLLABORATOR_GALLERY_STEPS as STEPS } from '../../../../constants/steps'

import './styles.scss'
import { PICTURE_STATUS, TRANSITION_TYPE } from '../../../../constants/status'
import { slug } from '../../../../helpers'
import EmptyPhotoSet from './empty-photoset'

import {
  getIsClient,
  getUser,
} from '../../../../store/authentication/selectors'
import { getAdditionalPictureB2CAndB2B } from '../../../../store/offer/selectors'
import {
  doGeneratePaymentLink,
  doCreateOrder,
  doGetAdditionalPictureProductBySlug,
  doNotifyPaymentOrderByEmail,
  doUploadAdditionalPicturesSummary,
} from '../../../../store/offer/actions'
import {
  doSelectMultiplePictures,
  doUpdateClientAndCollaborator,
} from '../../../../store/collaborators/actions'
import AdditionalPicturePaymentForm from '../../../app-components/additional-picture/payment'
const PhotoGallery = ({
  project,
  collaborator,
  user,
  pictures,
  selectPictures,
  downloadPicture,
  downloadAllPictures,
  navigate,
  fetchAdditionalPictureB2CAndB2B,
  additionalPictureB2CAndB2B,
  updateClientAndCollaborator,
  uploadAdditionalPicturesSummary,
  notifyPaymentOrderByEmail,
  generatePaymentLink,
  createOrder,
  isClient,
}) => {
  const [selectedPictures, setSelectedPictures] = useState([])
  const [zoomModalShow, setZoomModalShow] = useState(false)
  const [zoomModalPhotos, setZoomModalPhotos] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const [addPicOptionsModalShow, setAddPicOptionsModalShow] = useState(false)
  const [unselectedPictures, setUnselectedPictures] = useState([...pictures])
  const [selectedAdditionalPictures, setSelectedAdditionalPictures] = useState(
    []
  )
  const [additionalPicture, setAdditionalPicture] = useState(null)
  const [extraPicturesOptions, setExtraPicturesOptions] = useState([])
  const [allSelectedPictures, setAllSelectedPictures] = useState([])
  const [withAdditionalPicture, setWithAdditionalPicture] = useState(false)

  const status =
    collaborator !== null
      ? collaborator?.pivot.user_project_status
      : project?.pivot.user_project_status

  const initStep = () => {
    return status === PICTURE_STATUS.SELECTED || status === PICTURE_STATUS.FINAL
      ? STEPS.DOWNLOAD
      : STEPS.SELECT
  }
  const [step, setStep] = useState(initStep())

  const [initialSlide, setInitialSlide] = useState(null)
  const [showDetails, setShowDetails] = useState(false)


  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [orderPublicId, setOrderPublicId] = useState("")
  const [rcOrderId, setRcOrderId] = useState("")

  useEffect(() => {
    if (step === STEPS.SELECT) {
      if (pictures.length === 0)
        navigate('./photos-non-disponibles', { replace: true })
      else navigate('./preview', { replace: true })
    } else if (step === STEPS.CONFIRM) {
      navigate('./selection', { replace: true })
    } else if (step === STEPS.DOWNLOAD) {
      if (pictures.length === 0 || status === PICTURE_STATUS.SELECTED)
        navigate('./photos-non-disponibles', { replace: true })
      else navigate('./photos-finales', { replace: true })
    }
  }, [pictures, step])

  useEffect(() => {
    async function init() {
      await fetchAdditionalPictureB2CAndB2B()
    }

    init()
  }, [])

  const isLargeScreen = useMediaQuery({ query: '(min-device-width: 1200px)' })

  // maxPhotoToSelect
  const toSelectNb = project?.picture_per_collaborator
  // 12.08.2022 Enable Additional picture for B2B and B2C
  const selectionDone =
    allSelectedPictures.length >=
    toSelectNb + 
    project?.pivot.user_project_additional_pictures

  const goStep = targetStep => {
    // only authorize go back from 2nd tab to 1st
    if (step === STEPS.CONFIRM && parseInt(targetStep, 10) === STEPS.SELECT) {
      setStep(targetStep)
      navigate('./preview')
    }
  }
  const showZoomModal = items => {
    setZoomModalPhotos(items)
    setZoomModalShow(true)
  }

  const handleSelection = (e, checked, item, extraOptions) => {
    if (parseInt(step, 10) === STEPS.SELECT) {
      const id = selectedPictures.findIndex(i => i.id === item.id)
      if (id >= 0) {
        // update selectedPictures
        const filter = selectedPictures.filter(img => img.id !== item.id)
        setAllSelectedPictures(filter)
        setSelectedPictures(filter)
        setUnselectedPictures([...unselectedPictures, item])
        setSelectedAdditionalPictures([])
        setExtraPicturesOptions([])
      } else if (selectedPictures.length < toSelectNb) {
        setAllSelectedPictures([...selectedPictures, item])
        setSelectedPictures([...selectedPictures, item])
        const filter = unselectedPictures.filter(img => img.id !== item.id)
        setUnselectedPictures(filter)
        setSelectedAdditionalPictures([])
        setExtraPicturesOptions([])
      } else if (selectedPictures.length === toSelectNb) {
        // additional picture management
        const idExtra = selectedAdditionalPictures.findIndex(
          i => i.id === item.id
        )
        if (idExtra >= 0) {
          // update selectedPictures
          const filter = selectedAdditionalPictures.filter(
            img => img.id !== item.id
          )
          setAllSelectedPictures([...selectedPictures, ...filter])
          setSelectedAdditionalPictures(filter)
          setUnselectedPictures([...unselectedPictures, item])
          const filterOptionsSup = extraPicturesOptions.filter(
            extraImg => extraImg?.id !== item.id
          )
          setExtraPicturesOptions(filterOptionsSup)
        } else {
          setAllSelectedPictures([
            ...selectedPictures,
            ...selectedAdditionalPictures,
            item,
          ])
          setSelectedAdditionalPictures([...selectedAdditionalPictures, item])
          setExtraPicturesOptions([...extraPicturesOptions, extraOptions])
          const filter = unselectedPictures.filter(img => img.id !== item.id)
          setUnselectedPictures(filter)
        }
      }
    }
  }

  const makeTotalOrder = (extraPicturesOptions) => {
    let total = 0
    extraPicturesOptions.forEach((item) => {
      total += item.price
      Object.values(item.options_summary).forEach((option) => {
        total += option.price
      })
    })
    return total
  }

  const confirmAdditionalPicturesSelection = async () => {
    if (extraPicturesOptions.length !== 0) {
      try {
        // 12.08.2022 Enable Additional picture for B2B and B2C
        const clientOrCollaborator =
          !isClient && collaborator != null ? collaborator : user

        const createRevolutOrder = await createOrder(
          clientOrCollaborator,
          makeTotalOrder(extraPicturesOptions),
          'Photo(s) supplémentaire(s)'
        )

        if(createRevolutOrder && createRevolutOrder?.public_id){
          setOrderPublicId(createRevolutOrder?.public_id)
          setRcOrderId(createRevolutOrder?.id)
          setShowConfirmModal(false)
          setShowPaymentModal(true)
        }
        return true
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return false
      }
    }
    return false
  }

  const payAdditionalPicturesSelection = async () => {
    if (extraPicturesOptions.length !== 0) {
      try {
        // 12.08.2022 Enable Additional picture for B2B and B2C
        const clientOrCollaborator =
          !isClient && collaborator != null ? collaborator : user
        
        await uploadAdditionalPicturesSummary(
          clientOrCollaborator,
          project,
          extraPicturesOptions,
          isClient
        )
        await updateClientAndCollaborator(
          project,
          clientOrCollaborator,
          extraPicturesOptions
        )
        return true
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return false
      }
    }

    return false
  }
  const confirmSelection = async () => {
    const picturesId = selectedPictures.map((p) => p.id)
    // select additonal pictures too
    if (extraPicturesOptions.length > 0) {
      extraPicturesOptions.forEach((p) => picturesId.push(p.id))
    }

    const transition = await selectPictures(picturesId, TRANSITION_TYPE.SELECT)

    if (!transition) return false

    setShowConfirmModal(false)
    setShowPaymentModal(false)
    setShowSuccessModal(true)
    return true
  }
  // download single Picture
  const handleDownloadSingle = (picture, index) => {
    downloadPicture(collaborator !== null ? collaborator : user, picture, index)
  }
  // download all pictures
  const handleDownloadAll = () => {
    downloadAllPictures(collaborator !== null ? collaborator : user, project)
  }

  const makePictureOptions = options => {
    let pictureOptions = ''
    Object?.keys(options).forEach(opt => {
      if (parseInt(opt, 10) === 0) {
        pictureOptions += options[opt].name
      } else {
        pictureOptions += ` / ${options[opt].name}`
      }
    })
    return pictureOptions
  }
  const makePicturePrice = (options, extraDefaultPrice) => {
    let picturePrice = extraDefaultPrice
    Object.keys(options).forEach(opt => {
      picturePrice += parseInt(options[opt].price, 10)
    })
    return picturePrice
  }
  const makeExtraTotalPrice = extraPictures => {
    let extraTotalPrice = 0
    Object.keys(extraPictures).forEach(picture => {
      Object.keys(extraPictures[picture]).forEach((extra, index) => {
        extraTotalPrice += makePicturePrice(
          extraPictures[picture][index].options_summary,
          extraPictures[picture][index].price
        )
      })
    })
    return extraTotalPrice
  }

  const handlePaymentSuccess = async () => {
     const transition = await confirmSelection()
     if (transition) await payAdditionalPicturesSelection()
  }

  const ExtraOptionsSummary = (extraPictures)  => (
    <Row className="container-fluid mx-0 px-0">
      <Col sm={12} className="justify-content-center mt-3">
        <h3>Photos supplémentaires</h3>
      </Col>
      <Col sm={12} className="container-fluid summary-detail">
        {Object.keys(extraPictures).map(picture =>
          Object.keys(extraPictures[picture]).map((extra, index) => (
            <Row className="summary-detail-line">
              <Col xs={10} className="px-0 text-left">
                <strong>Photo {index + 1} - </strong>
                {makePictureOptions(
                  extraPictures[picture][index]?.options_summary
                )}
              </Col>
              <Col xs={2} className="px-0 text-right">
                <strong>
                  {makePicturePrice(
                    extraPictures[picture][index].options_summary,
                    extraPictures[picture][index].price
                  )}{' '}
                  €
                </strong>
              </Col>
            </Row>
          ))
        )}
      </Col>
      <Col sm={12} className="container-fluid summary-total">
        <Row className="summary-total-line">
          <Col xs={8} className="px-0 text-left">
            <strong>TOTAL</strong>
          </Col>
          <Col xs={4} className="px-0 text-right">
            <strong>{makeExtraTotalPrice(extraPictures)} €</strong>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  const RenderSelectTab = () => (
    <>
      <Row className="gallery-heading  my-4 mx-0">
        {toSelectNb > 1 ? (
          <>
            {selectionDone && selectedAdditionalPictures.length !== 0 ? (
              <p>
                Vous avez ajouté{' '}
                <strong>
                  {selectedAdditionalPictures.length}{' '}
                  {selectedAdditionalPictures.length > 1
                    ? `photos supplémentaires`
                    : `photo supplémentaire`}
                </strong>{' '}
                à votre forfait initial.
              </p>
            ) : (
              <p>
                Sélectionnez vos <strong>{toSelectNb} photos préférées,</strong>{' '}
                celles-ci seront envoyées à nos retoucheurs.
              </p>
            )}
          </>
        ) : (
          <p>
            Sélectionnez votre <strong>photo préférée,</strong> celle-ci sera
            envoyée à nos retoucheurs.
          </p>
        )}
      </Row>
      <Row className="mx-2 photos-row">
        {pictures.map((item, index) => (
          <Col key={item.id} className="px-2 pb-3" xs={6} md={3}>
            <GalleryItem
              item={item}
              index={index}
              isChecked={
                selectedPictures.findIndex(i => i.id === item.id) !== -1 ||
                selectedAdditionalPictures.findIndex(i => i.id === item.id) !== -1
              }
              setModalShow={() => showZoomModal(pictures)}
              setModalAddPicShow={setAddPicOptionsModalShow}
              setAdditionalPicture={setAdditionalPicture}
              setInitialSlide={setInitialSlide}
              handleSelection={handleSelection}
              selectionDone={selectionDone}
              step={step}
              selectedAdditionalPictures={selectedAdditionalPictures}
              // 12.08.2022 Enable Additional picture for B2B and B2C
              product={additionalPictureB2CAndB2B}
              user={user}
            />
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center mx-0">
        <Button
          disabled={!selectionDone}
          className="gallery-button"
          size="lg"
          variant={selectionDone ? 'secondary' : 'primary'}
          onClick={() => {
            setStep(STEPS.CONFIRM)
            navigate('./selection')
          }} // go to step 2
        >
          AJOUTER À MA SÉLECTION
        </Button>
      </Row>
    </>
  )
  const RenderConfirmTab = () => (
    <>
      <Row className="gallery-heading  my-4 mx-0">
        <Col className="text-center">
          <p>
            <strong>Ceci est votre sélection</strong> de photos qui seront
            envoyées au retoucheur.
          </p>
        </Col>
      </Row>
      <Row className="m-0 photos-row">
        {allSelectedPictures.map((item, index) => (
          <Col
            className="photos-col selected-items mb-4"
            xs={6}
            md={3}
            key={item.id}
          >
            <GalleryItem
              item={item}
              index={index}
              isChecked
              setModalShow={() => showZoomModal(selectedPictures)}
              setModalAddPicShow={setAddPicOptionsModalShow}
              setAdditionalPicture={setAdditionalPicture}
              setInitialSlide={setInitialSlide}
              handleSelection={handleSelection}
              selectionDone={selectionDone}
              step={step}
              selectedAdditionalPictures={selectedAdditionalPictures}
            />
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center my-3 mx-0">
        {/* show Modal Confirm */}
        <Button
          className="gallery-button"
          size="lg"
          variant="primary"
          disabled={!selectionDone}
          onClick={() => setShowConfirmModal(true)}
        >
          VALIDER MA SELECTION
        </Button>
      </Row>
    </>
  )

  return (
    <>
      <Container className="photo-gallery">
        <Tabs
          activeKey={step}
          onSelect={(key) => goStep(key)}
          id="photo-gallery-tabs"
        >
          <Tab eventKey={STEPS.SELECT} title="Choix des photos">
            <Router>
              <RenderSelectTab path="/preview" />
              <EmptyPhotoSet
                path="/photos-non-disponibles"
                step={STEPS.SELECT}
              />
            </Router>
          </Tab>
          <Tab
            eventKey={STEPS.CONFIRM}
            title={isLargeScreen ? 'Ma sélection' : 'Sélection'}
            disabled={step < STEPS.CONFIRM}
          >
            <Router>
              <RenderConfirmTab path="/selection" />
            </Router>
          </Tab>
          <Tab
            eventKey={STEPS.DOWNLOAD}
            title="Photos finales"
            disabled={step < STEPS.DOWNLOAD}
          >
            <Router>
              <RetouchedSet
                path="/photos-finales"
                pictures={pictures}
                handleDownload={handleDownloadSingle}
                handleDownloadAll={handleDownloadAll}
              />
              <EmptyPhotoSet
                path="/photos-non-disponibles"
                step={STEPS.DOWNLOAD}
              />
            </Router>
          </Tab>
        </Tabs>
      </Container>
      {/* Mobile Footer Menu */}
      {/* Add to selection button */}
      {!isLargeScreen && selectionDone && step === STEPS.SELECT && (
        <FixedBottomButton
          text="AJOUTER À MA SÉLECTION"
          handleClick={() => setStep(STEPS.CONFIRM)}
          containerClass="mobile-gallery-button"
        />
      )}
      {/* Confirm button */}
      {!isLargeScreen && step === STEPS.CONFIRM && (
        <FixedBottomButton
          text="CONFIRMER MES CHOIX"
          handleClick={() => setShowConfirmModal(true)}
          containerClass="mobile-gallery-button confirm-step"
        />
      )}
      {/* Collaborator detail bottom bar */}
      {!isLargeScreen && step === STEPS.SELECT && (
        <>
          <Container
            className={`menu-details ${
              showDetails ? 'show-menu' : 'hide-menu'
            }`}
          >
            <Row className="p-3">
              <Col xs={12} className="mb-3 align-items-center d-flex">
                <Icon name="icon-mail" iconClass="detail-icon" />
                <span className="detail-text">
                  {collaborator !== null ? collaborator.email : user.email}
                </span>
              </Col>
              <Col xs={12} className="align-items-center d-flex">
                <Icon name="icon-date" iconClass="detail-icon" />
                <span className="detail-text">
                  {moment(project.prestation_date, 'YYYY-MM-DD').format('LL')}
                </span>
              </Col>
            </Row>
          </Container>
          <Container className="mobile-gallery-menu p-3" fluid>
            <Row
              className=" align-items-center"
              onClick={() => setShowDetails(!showDetails)}
            >
              <Col className="ml-4">
                <Icon name="icon-profile" iconClass="collaborator-icon" />
                <span className="collaborator-info text-uppercase">
                  {collaborator !== null
                    ? `${collaborator.lastname} ${collaborator.firstname}`
                    : `${user.lastname} ${user.firstname}`}
                </span>
              </Col>
              <Col xs="auto">
                <Icon
                  name="icon-arrow-down"
                  iconClass={`arrow ${showDetails ? '' : 'up'}`}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}

      {/* zoomedView Modal */}
      <GallerySliderModal
        show={zoomModalShow}
        onHide={() => setZoomModalShow(false)}
        initialSlide={initialSlide}
        handleCheck={handleSelection}
        isChecked={(item) =>
          selectedPictures.findIndex((i) => i.id === item.id) !== -1 ||
          selectedAdditionalPictures.findIndex((i) => i.id === item.id) !== -1
        }
        selectionDone={selectionDone}
        photos={zoomModalPhotos}
      />
      {/* Modal AddPicChoices */}
      {/* 12.08.2022 Enable Additional picture for B2B and B2C */}
      {
        <PictureOptionsModal
          heading="Photo supplémentaire"
          show={addPicOptionsModalShow}
          onHide={() => setAddPicOptionsModalShow(false)}
          product={additionalPictureB2CAndB2B}
          className="picture-options-modal"
          additionalPicture={additionalPicture}
          handleSelection={handleSelection}
        />
      }
      {/* Step 2 Confirmation Modal */}
      <ModalCard
        heading="Ma Sélection"
        icon={
          <Icon name="icon-edit-picture" iconClass="card-body-icon" paths={3} />
        }
        title={
          <>
            <p className="mb-3">
              Attention, toute sélection validée ne sera{' '}
              <strong>pas modifiable.</strong> Les photos de la sélection
              validée seront envoyées aux retoucheurs.
            </p>
            {selectionDone && extraPicturesOptions.length !== 0 ? (
              <ExtraOptionsSummary extraPictures={extraPicturesOptions} />
            ) : (
              <br />
            )}
          </>
        }
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        className="gallery-modal-card"
      >
        <Button
          onClick={async () => {
            if (extraPicturesOptions.length !== 0) {
              setWithAdditionalPicture(true)
              await confirmAdditionalPicturesSelection()
            } else {
              setWithAdditionalPicture(false)
              await confirmSelection()
            }
          }}
          variant="secondary"
          size="lg"
          className="card-modal-button"
        >
          {isLargeScreen ? 'CONFIRMER MA SÉLECTION' : 'CONFIRMER MES CHOIX'}
        </Button>
      </ModalCard>

      {/* Step 2 Payment Modal */}
      <ModalCard
        heading="Paiement"
        title={
          <>
            {selectionDone && extraPicturesOptions.length !== 0 ? (
              <ExtraOptionsSummary
                extraPictures={extraPicturesOptions}
                titleDisplay={false}
              />
            ) : (
              <br />
            )}
          </>
        }
        show={showPaymentModal}
        onHide={() => setShowPaymentModal(false)}
        className="gallery-modal-card"
      >
        <AdditionalPicturePaymentForm
          user={user}
          orderPublicId={orderPublicId}
          rcOrderId={rcOrderId}
          handleSuccess={handlePaymentSuccess}
        />
      </ModalCard>

      {/* //Step 2 Success Modal */}
      <ModalCard
        heading="Confirmation"
        icon={
          <span className="card-body-icon checked-icon icon-wrapper icon-checked" />
        }
        title={
          <p>
            <strong>{withAdditionalPicture?'Votre paiement a bien été accepté,':'Vos choix ont bien été pris en compte,'}</strong> <br />
            vos photos partent en retouche et seront bientôt disponibles.
          </p>
        }
        show={showSuccessModal}
        onHide={() => {
          setStep(STEPS.DOWNLOAD)
          navigate('./photos-non-disponibles')
          setShowSuccessModal(false)
        }}
      >
        <Link
          to={
            collaborator === null
              ? '/compte/projets/'
              : `/compte/projets/${slug(project.name)}`
          }
          className="card-modal-button btn btn-primary button-primary text-white my-3"
        >
          {collaborator === null
            ? 'RETOURNER À MES PROJETS'
            : 'RETOURNER AU PROJET'}
        </Link>
      </ModalCard>
    </>
  )
}
PhotoGallery.defaultProps = { collaborator: null, user: null }
PhotoGallery.propTypes = {
  collaborator: propTypes.objectOf(propTypes.any),
  user: propTypes.objectOf(propTypes.any),
  project: propTypes.objectOf(propTypes.any).isRequired,
  pictures: propTypes.objectOf(propTypes.object).isRequired,
  selectPictures: propTypes.func.isRequired,
  downloadPicture: propTypes.func.isRequired,
  downloadAllPictures: propTypes.func.isRequired,
  navigate: propTypes.func.isRequired,
  updateClientAndCollaborator: propTypes.func.isRequired,
  uploadAdditionalPicturesSummary: propTypes.func.isRequired,
  notifyPaymentOrderByEmail: propTypes.func.isRequired,
  generatePaymentLink: propTypes.func.isRequired,
  createOrder: propTypes.func.isRequired,
  additionalPictureB2CAndB2B: propTypes.objectOf(propTypes.any).isRequired,
  fetchAdditionalPictureB2CAndB2B: propTypes.func.isRequired,
  isClient: propTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  additionalPictureB2CAndB2B: getAdditionalPictureB2CAndB2B(state),
  user: getUser(state),
  isClient: getIsClient(state),
})
const mapDispatchToProps = dispatch => ({
  updateClientAndCollaborator: (project, clientOrCollaborator, attributeToUpdate) =>
    dispatch(
      doUpdateClientAndCollaborator(
        project,
        clientOrCollaborator,
        attributeToUpdate
      )
    ),
  uploadAdditionalPicturesSummary: (
    clientOrCollaborator,
    project,
    extraPicturesOptions,
    isClient
  ) =>
    dispatch(
      doUploadAdditionalPicturesSummary(
        clientOrCollaborator,
        project,
        extraPicturesOptions,
        isClient
      )
    ),
  notifyPaymentOrderByEmail: (
    clientOrCollaborator,
    extraPicturesOptions,
    payment
  ) =>
    dispatch(
      doNotifyPaymentOrderByEmail(
        clientOrCollaborator,
        extraPicturesOptions,
        payment
      )
    ),
  generatePaymentLink: (clientOrCollaborator, extraPicturesOptions) =>
    dispatch(doGeneratePaymentLink(clientOrCollaborator, extraPicturesOptions)),
  createOrder: (clientOrCollaborator, amount, description) =>
    dispatch(doCreateOrder(clientOrCollaborator, amount, description)),
  fetchAdditionalPictureB2CAndB2B: () =>
    dispatch(doGetAdditionalPictureProductBySlug()),
  selectPictures: (pictures, transition) =>
    dispatch(doSelectMultiplePictures(pictures, transition)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGallery)
// todo refactor this component (Separate of concern)
