import React, { useRef } from 'react'
import propTypes from 'prop-types'
import { Row, Col, Container, Button, Badge } from 'react-bootstrap'
import Checkbox from '../../../../app-components/checkbox'
import Icon from '../../../../app-components/icon'
import StatusBadge from '../../../../app-components/status-badge'
import EmptyCollaboratorList from '../empty-collaborator-list'
import { slug } from '../../../../../helpers'
import LoadingIndicator from '../../../../app-components/loading-indicator'
import './styles.scss'

const MobileCollaboratorList = ({
  project,
  isLoading,
  collaborators,
  navigate,
  isChecked,
  handleSelect,
  deselectAll,
  selectedList,
  showAddCollabModal,
  showMore,
  showDeleteCollabModal,
  isMore,
  importCollaborators,
  handleSearch,
  noSearchResults,
  renderDate,
}) => {
  const importCsv = useRef(null)
  const importFile = () => {
    importCsv.current.value = null
    importCsv.current.click()
  }

  return (
    <>
      <Container className="mobile-collaborator-menu">
        <Row className="collaborator-list-menu justify-content-between align-items-center mx-0 py-2">
          <Col xs="auto" className="collaborator-search-wrapper">
            <Button
              variant="flat"
              size="lg"
              className="collaborator-search-button"
            >
              <Icon name="icon-search" />
            </Button>
            <input
              type="text"
              className="collaborator-search"
              onChange={e => handleSearch(e.target.value)}
            />
          </Col>
          <Col xs="auto">
            {selectedList.length === 0 && (
              <Button
                variant="flat"
                size="lg"
                className="m-0"
                onClick={() => {
                  importCsv.current.click()
                }}
              >
                <Icon name="icon-import-csv" iconClass="text-primary" />
                <input
                  id="import-csv"
                  ref={importCsv}
                  className="d-none"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={importCollaborators}
                />
              </Button>
            )}
            {selectedList.length === 0 && (
              <Button
                variant="flat"
                size="lg"
                onClick={() => showAddCollabModal(true)}
                className="pr-0"
              >
                <Icon
                  name="icon-create-collaborator"
                  iconClass="text-primary"
                />
              </Button>
            )}
          </Col>
        </Row>
      </Container>
      <Container className="mobile-collaborator-list" fluid>
        {isLoading && <LoadingIndicator />}
        {collaborators.length === 0 ? (
          <EmptyCollaboratorList
            handleAddCollaborator={showAddCollabModal}
            handleAddCollaboratorList={importCollaborators}
            noSearchResults={noSearchResults}
          />
        ) : (
          collaborators.map((collab, index) => (
            <Row
              className={`mobile-collaborator-list-item ${
                isChecked(collab) ? 'selected' : ''
              }`}
              key={collab.id}
            >
              <Col
                xs="auto"
                className="align-self-center collaborator-checkbox-wrapper"
              >
                <Checkbox
                  id={`check-${index}`}
                  className="collaborator-list-checkbox"
                  onChange={() => handleSelect(collab)}
                  onClick={e => e.stopPropagation()}
                  isChecked={isChecked(collab)}
                />
              </Col>
              <Col>
                <p className="mb-2">
                  <strong>
                    {collab.lastname} {collab.firstname}
                  </strong>
                  <br />
                  Date de la séance : {renderDate(project, collab)}
                  <br />
                  Présence :{' '}
                  {collab.pivot.presence === 'confirmed'
                    ? 'Confirmée'
                    : 'Non confirmée'}
                </p>
                <StatusBadge
                  status={collab.pivot.user_project_status}
                  badgeClass="mobile-collaborator-list-item-status"
                />
                <Button
                  className="mobile-collaborator-list-item-edit-btn"
                  variant="flat"
                  size="lg"
                  onClick={() =>
                    navigate(slug(`${collab.lastname}-${collab.firstname}`))
                  }
                >
                  <Icon name="icon-edit-pen" paths={1} />
                </Button>
              </Col>
            </Row>
          ))
        )}
        {collaborators.length > 11 && (
          <Row className="justify-content-center text-center">
            <Col md="auto">
              <Button
                variant="primary"
                disabled={!isMore}
                size="small"
                onClick={showMore}
              >
                VOIR PLUS DE COLLABORATEURS
              </Button>
            </Col>
          </Row>
        )}
      </Container>
      {selectedList.length > 0 && (
        <Container fluid className="mobile-collaborator-list-bottom-menu">
          <Row className="align-items-center">
            <Col>
              <Badge className="selected-number">{selectedList.length}</Badge>
            </Col>
            <Col>
              <Button variant="flat" onClick={importFile}>
                <Icon iconClass="text-primary" name="icon-import-csv" />
                <input
                  id="import-csv-mobile"
                  ref={importCsv}
                  className="d-none"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={importCollaborators}
                />
              </Button>
            </Col>{' '}
            <Col>
              <a>
                <Icon iconClass="text-primary" name="icon-download" />
              </a>
            </Col>
            <Col>
              <a onClick={() => showDeleteCollabModal(true)}>
                <Icon iconClass="text-primary" name="icon-delete" />
              </a>
            </Col>
            <Col>
              <Button variant="flat" onClick={deselectAll}>
                <Icon iconClass="close-menu icon-bottom" name="icon-close" />
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

MobileCollaboratorList.propTypes = {
  isLoading: propTypes.bool.isRequired,
  project: propTypes.objectOf(propTypes.any).isRequired,
  collaborators: propTypes.objectOf(propTypes.object).isRequired,
  navigate: propTypes.func.isRequired,
  isChecked: propTypes.func.isRequired,
  handleSelect: propTypes.func.isRequired,
  deselectAll: propTypes.func.isRequired,
  selectedList: propTypes.arrayOf(propTypes.object).isRequired,
  importCollaborators: propTypes.func.isRequired,
  showAddCollabModal: propTypes.func.isRequired,
  showDeleteCollabModal: propTypes.func.isRequired,
  showMore: propTypes.func.isRequired,
  isMore: propTypes.bool.isRequired,
  handleSearch: propTypes.func.isRequired,
  noSearchResults: propTypes.bool.isRequired,
  renderDate: propTypes.func.isRequired,
}
export default MobileCollaboratorList
