import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import propTypes from 'prop-types'

import * as yup from 'yup'
import useForm from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import DashedHeading from '../dashed-heading'
import ErrorMessage from '../form-error-message'
import './styles.scss'

const AccountForm = ({
  handleSubmit,
  user,
  isCollaborator,
  isAccountManager,
  isClient,
}) => {
  const [form, setForm] = useState({
    position: user.position_held || '',
  })
  const { position } = form

  const formSchema = yup.object().shape({
    position: yup
      .string()
      .required()
      .max(100),
  })
  const {
    register,
    errors,
    formState,
    setValue,
    handleSubmit: wrapSubmit,
  } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
  })

  const handleChange = ({ target: { value, name } }) => {
    // validate form on input change
    setValue(name, value, true)
    setForm({ ...form, [name]: value })
  }
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  let title =
    isCollaborator || isClient
      ? 'Vos informations'
      : 'Informations du gestionnaire de compte'

  if (isMobile) title = 'Informations'

  return (
    <Container className="account-manager-form pt-lg-3">
      <DashedHeading title={title} />
      <Form onSubmit={() => wrapSubmit(handleSubmit(form))}>
        {isAccountManager && (
          <Form.Row className="my-2 mb-lg-3">
            <Form.Group
              as={Col}
              xs={12}
              lg
              className="form-label-group"
              controlId="company"
            >
              <Form.Control
                defaultValue={user?.companyname}
                placeholder="Nom de l'entreprise"
                name="company"
                disabled
              />
              <Form.Label>Nom de l&apos;entreprise</Form.Label>
            </Form.Group>
          </Form.Row>
        )}
        <Form.Row className="my-2 mb-lg-3">
          <Form.Group
            className="form-label-group mb-2"
            as={Col}
            xs={12}
            lg
            controlId="firstName"
          >
            <Form.Control
              defaultValue={user.firstname}
              name="firstName"
              placeholder="Prénom"
              disabled
            />
            <Form.Label>Prénom</Form.Label>
          </Form.Group>
          <Col lg="auto" className="d-none d-lg-block mx-lg-3" />
          <Form.Group
            className="form-label-group mt-2 mt-lg-0"
            as={Col}
            xs={12}
            lg
            controlId="lastName"
          >
            <Form.Control
              defaultValue={user.lastname}
              name="lastName"
              placeholder="Nom"
              disabled
            />
            <Form.Label>Nom</Form.Label>
          </Form.Group>
        </Form.Row>

        <Form.Row className="my-2 mb-lg-3">
          <Form.Group
            className="form-label-group"
            as={Col}
            xs={12}
            controlId="email"
          >
            <Form.Control
              defaultValue={user.email}
              placeholder="Email"
              name="email"
              disabled
            />
            <Form.Label>Email</Form.Label>
          </Form.Group>
        </Form.Row>

        <Form.Row className="my-2 mb-lg-3">
          <Form.Group
            className="form-label-group mb-2"
            as={Col}
            xs={12}
            lg
            controlId="tel"
          >
            <Form.Control defaultValue={user.tel} name="tel" disabled />
            <Form.Label>Téléphone fixe </Form.Label>
          </Form.Group>
          <Col lg="auto" className="d-none d-lg-block mx-lg-3" />
          <Form.Group
            className="form-label-group mt-2 mt-lg-0"
            as={Col}
            xs={12}
            lg
            controlId="tel-mobile"
          >
            <Form.Control
              defaultValue={user.mobile}
              name="tel-mobile"
              disabled
            />
            <Form.Label>Téléphone portable</Form.Label>
          </Form.Group>
        </Form.Row>
        {isAccountManager && (
          <Form.Row className="my-2 mb-lg-3">
            <Form.Group
              className="form-label-group"
              as={Col}
              xs={12}
              controlId="position"
            >
              <Form.Control
                defaultValue={user.position || position}
                ref={register}
                onChange={handleChange}
                isInvalid={typeof errors.position !== 'undefined'}
                placeholder="Titre du poste occupé"
                name="position"
              />
              <Form.Label>Poste occupé </Form.Label>
              <ErrorMessage name="position" errors={errors} />
            </Form.Group>
          </Form.Row>
        )}
        <Row className="justify-content-center my-2 mb-lg-3">
          <Button
            size="lg"
            variant={
              formState.dirty && formState.isValid ? 'secondary' : 'disabled'
            }
            onClick={() => wrapSubmit(handleSubmit(form))}
            disabled={!formState.dirty || !formState.isValid}
          >
            ENREGISTRER
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
AccountForm.propTypes = {
  handleSubmit: propTypes.func.isRequired,
  user: propTypes.objectOf(propTypes.any).isRequired,
  isCollaborator: propTypes.bool.isRequired,
  isAccountManager: propTypes.bool.isRequired,
  isClient: propTypes.bool.isRequired,
}
export default AccountForm
