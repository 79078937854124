import React from 'react'
import { Button, Nav } from 'react-bootstrap'
import propTypes from 'prop-types'

import './styles.scss'
import Icon from '../../../app-components/icon'
import { renderDate } from '../../../../helpers'

const GalleryHeader = ({ project, collaborator, deleteCollab }) => {
  return (
    <Nav className="gallery-header pt-4 py-3 px-4 bg-primary justify-content-between">
      <Nav.Item className="gallery-header-item">
        <Icon name="icon-profile" iconClass="gallery-header-item-icon" />
        <span className="gallery-header-item-text text-uppercase">
          {collaborator.lastname} {collaborator.firstname}
        </span>
      </Nav.Item>
      <div>
        <Nav.Item className="gallery-header-item mr-3">
          <Icon
            name="icon-mail"
            iconClass="gallery-header-item-icon font-weight-bold"
          />
          <span className="gallery-header-item-text">
            {' '}
            {collaborator.email}
          </span>
        </Nav.Item>
        {project !== null && (
          <>
            <Nav.Item className="gallery-header-item mx-3">
              <Icon name="icon-date" iconClass="gallery-header-item-icon" />
              <span className="gallery-header-item-text">
                {renderDate(project, collaborator)}
              </span>
            </Nav.Item>
            <Nav.Item className="gallery-header-item ml-3">
              <Button
                size="sm"
                onClick={deleteCollab}
                variant="flat"
                className="gallery-header-item-button py-0"
              >
                <Icon
                  name="icon-delete"
                  iconClass="gallery-header-item-icon mr-0"
                />
              </Button>
            </Nav.Item>
          </>
        )}
      </div>
    </Nav>
  )
}
GalleryHeader.propTypes = {
  project: propTypes.objectOf(propTypes.any),
  collaborator: propTypes.objectOf(propTypes.any).isRequired,
  deleteCollab: propTypes.func.isRequired,
}
GalleryHeader.defaultProps = {
  project: null,
}
export default GalleryHeader
