import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './styles.scss'

const AdditionalPictureBanner = () => (
  <Row className="additionnal-picture-banner">
    <Col className="additionnal-picture-banner-text">
      <p>
        <strong>Photo supplémentaire</strong>
      </p>
    </Col>
  </Row>
)
export default AdditionalPictureBanner
