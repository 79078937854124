import React from 'react'
import propTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import Icon from '../../../app-components/icon'
import Button from '../../../app-components/button'
import LoadingIndicator from '../../../app-components/loading-indicator'
import './styles.scss'

const BookingSummary = ({
  deleteReservations,
  editReservations,
  modified,
  selectedStudio,
  selectedDate,
  isLoading,
  isB2C,
}) =>
  isLoading || selectedStudio === null ? (
    <LoadingIndicator />
  ) : (
    <Container className="my-reservations my-4">
      <Row className="text-center">
        <Col>
          <p className="text-center my-reservations-title">
            Votre réservation a bien été prise en compte, votre séance aura lieu
            :
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <div className="my-reservations-table">
            <div className="my-reservations-buttons">
              <button
                type="button"
                className="my-reservations-button"
                onClick={editReservations}
              >
                <Icon name="icon-edit-pen" />
              </button>
              {!isB2C && (
                <button
                  type="button"
                  className="my-reservations-button"
                  onClick={deleteReservations}
                >
                  <Icon name="icon-delete" />
                </button>
              )}
            </div>
            <div className="my-reservations-table-row my-reservations-table-header">
              <div className="my-reservations-table-item">Lieu</div>
              <div className="my-reservations-table-item">Date</div>
              <div className="my-reservations-table-item">Horaire</div>
            </div>
            <div className="my-reservations-table-row">
              <h4 className="my-reservations-table-studio">
                {selectedStudio.name}
              </h4>
              <div className="my-reservations-table-item">
                {selectedStudio.address}, {selectedStudio.postalcode}{' '}
                {selectedStudio.city}
              </div>
              <div className="my-reservations-table-item">
                {selectedDate.format('dddd DD MMMM')}
              </div>
              <div className="my-reservations-table-item">
                {selectedDate.format('HH:mm')}
              </div>
            </div>
            {modified && (
              <span className="my-reservations-modified">
                <Icon name="icon-check" />
                <span className="my-reservations-modified-info">Modifiée</span>
              </span>
            )}
          </div>
        </Col>
      </Row>
      <div className="text-center">
        <Button
          type="button"
          variant="primary"
          to="/compte/projets"
          name="Retourner à mes projets"
        />
      </div>
    </Container>
  )

BookingSummary.propTypes = {
  editReservations: propTypes.func.isRequired,
  deleteReservations: propTypes.func.isRequired,
  modified: propTypes.bool.isRequired,
  selectedStudio: propTypes.objectOf(propTypes.any).isRequired,
  selectedDate: propTypes.objectOf(propTypes.any).isRequired,
  isLoading: propTypes.bool.isRequired,
  isB2C: propTypes.bool.isRequired,
}

export default BookingSummary
