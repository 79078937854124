import React from 'react'
import propTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import Icon from '../../../app-components/icon'
import Button from '../../../app-components/button'
import './styles.scss'

const CancelledSession = ({ selectedStudio, selectedDate }) => (
  <Container className="booking-removed-summary my-4">
    <Icon name="icon-delete-yellow" paths={4} />
    <p className="booking-removed-summary-studio">
      Votre séance{' '}
      <strong>
        « {selectedStudio.address}, {selectedStudio.postalcode}{' '}
        {selectedStudio.city}, {selectedDate.format('dddd DD MMMM')} à{' '}
        {selectedDate.format('HH:mm')} »
      </strong>{' '}
      a bien été annulée.
    </p>
    <Button
      type="button"
      variant="secondary"
      to="/compte/projets"
      name="Retourner à mes projets"
    />
  </Container>
)

CancelledSession.propTypes = {
  selectedStudio: propTypes.objectOf(propTypes.any).isRequired,
  selectedDate: propTypes.objectOf(propTypes.any).isRequired,
}

export default CancelledSession
