import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import { Router } from '@reach/router'
import moment from 'moment'
import 'moment/locale/fr'

import * as yup from 'yup'
import useForm from 'react-hook-form'
import Heading from '../../app-components/dashed-heading'
import OrderSession from './order-session'
import BookingSummary from './booking-summary'
import SessionBookingModal from './session-booking-modal'
import CancelledSession from './cancelled-session'
import { getUser } from '../../../store/authentication/selectors'
import { getProjectBySlug } from '../../../store/projects/selectors'
import {
  doCancelStudioSession,
  doConfirmStudioSession,
  doGetStudioList,
  doGetStudioSessionList,
  doGetStudioTimeslots,
  doUpdateStudioSession,
} from '../../../store/studios/actions'
import GalleryHeader from '../project-detail/gallery-header'
import {
  getSessionByProject,
  getStudioById,
  getStudios,
  getTimeslots,
} from '../../../store/studios/selectors'
import ErrorMessage from '../../app-components/form-error-message'
import { doGetProjectList } from '../../../store/projects/actions'

const SessionBooking = ({
  project,
  user,
  studioList,
  getStudio,
  timeslots,
  getStudioList,
  getTimeslotList,
  getStudioSessionList,
  projectSession,
  confirmStudioSession,
  updateStudioSession,
  cancelStudioSession,
  navigate,
  isLoading,
  fetchProjectList,
}) => {
  // init studios & sessions
  useEffect(() => {
    async function init() {
      await getStudioList()
      await getStudioSessionList(user.id)
    }

    init()
  }, [])

  const session = projectSession(project.id)

  // default route is /reservation/reserver
  // redirect if there is already a booked session
  useEffect(() => {
    async function redirect() {
      await navigate('./', { replace: true })
    }

    if (session !== null) redirect()
  }, [session])

  const [studio, setStudio] = useState(null)

  const [date, setDate] = useState(
    session !== null
      ? moment.utc(session.start, 'YYYY-MM-DD HH:mm:ss')
      : moment.utc()
  )

  const [modified, changeModifyState] = useState(false)
  const [popupState, changePopupState] = useState(false)
  const [popupType, changePopupType] = useState('')
  const [error, setError] = useState('')
  // phone number form for confirm modal
  const [form, setForm] = useState({
    tel: null,
  })
  const formSchema = yup.object().shape({
    tel: yup
      .string()
      .required()
      .matches(/0[0-9]{9}/),
  })

  const {
    register,
    errors,
    handleSubmit,
    setValue,
    formState,
    triggerValidation,
  } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
  })
  // update state values
  useEffect(() => {
    if (session !== null && !popupState) {
      setStudio(getStudio(session.photographer_id))
      setDate(moment.utc(session.start, 'YYYY-MM-DD HH:mm:ss'))
    }
  }, [getStudio, session])

  const cancel = async () => {
    setError('')
    const success = await cancelStudioSession(session.id)
    if (success) {
      navigate('annuler-seance')
      changePopupState(!popupState)
    }
    setError("Une erreur s'est produite, veuillez réessayer.")
  }
  const confirm = async () => {
    setError('')
    if (formState.isValid) {
      const success = await confirmStudioSession(
        studio.id,
        project.id,
        user.id,
        date.format('YYYY-MM-DD[T]HH:mm'),
        form.tel
      )
      if (success === 'conflict') {
        changePopupType('conflict')
      }
      if (success === true) {
        changePopupState(!popupState)
        await navigate('./', {}, true)
      }
      if (success === false) {
        setError("Une erreur s'est produite, veuillez réessayer.")
      }
    } else {
      await triggerValidation()
    }
  }
  const update = async () => {
    setError('')

    const success = await updateStudioSession(
      session.id,
      date.format('YYYY-MM-DD[T]HH:mm'),
      project.type === 'B2C'
    )
    if (success === 'conflict') {
      changePopupType('conflict')
    }
    if (success === true) {
      changePopupState(!popupState)
      changeModifyState(!modified)
      fetchProjectList()
      await navigate('./', {}, true)
    }
    if (success === false)
      setError("Une erreur s'est produite, veuillez réessayer.")
  }

  const modify = async () => {
    changePopupState(!popupState)
    await navigate('modifier')
  }

  const validate = ({ selectedStudio, selectedDate }, isUpdate) => {
    setError('')
    setStudio(getStudio(selectedStudio))
    setDate(selectedDate)
    changePopupState(!popupState)
    changePopupType(isUpdate ? 'update' : 'confirm')
  }

  const handleChange = ({ target: { value } }) => {
    // validate form on input change
    setValue('tel', value, true)
    setForm({ tel: value })
  }
  const phoneForm = () => (
    <Form onSubmit={() => handleSubmit(confirm)}>
      <Row className="text-left">
        <Col md={{ span: 10, offset: 1 }} className="text-center">
          <p>
            Pour confirmer votre séance, vous devez indiquer votre numéro de
            téléphone :
          </p>
        </Col>
        <Form.Group
          as={Col}
          xs={12}
          lg={{ span: 10, offset: 1 }}
          className="form-label-group"
          controlId="form-tel"
        >
          <Form.Control
            type="tel"
            placeholder=" "
            name="tel"
            ref={register}
            onChange={handleChange}
            defaultValue={form.tel}
            isInvalid={typeof errors.tel !== 'undefined'}
            autoFocus
          />
          <Form.Label>Numéro de téléphone</Form.Label>
          <ErrorMessage name="tel" errors={errors} />
        </Form.Group>
      </Row>
    </Form>
  )

  return (
    <>
      <Container>
        <Heading title={project.name} className="text-left" />
        <MediaQuery minWidth={992}>
          <GalleryHeader collaborator={user} />
        </MediaQuery>
      </Container>
      <Router primary={false}>
        <BookingSummary
          path="/"
          deleteReservations={() => {
            setError('')
            changePopupState(!popupState)
            if (date.diff(moment(), 'hours') > 48) changePopupType('annuler')
            else changePopupType('cancel-limit')
          }}
          editReservations={() => {
            setError('')
            changePopupState(!popupState)
            if (date.diff(moment(), 'hours') > 24) changePopupType('modifier')
            else changePopupType('update-limit')
          }}
          modified={modified}
          selectedStudio={studio}
          selectedDate={date}
          isLoading={isLoading}
          isB2C={project.type === 'B2C'}
        />
        <OrderSession
          path="reserver"
          project={project}
          initialStudio={studio}
          initialDate={date}
          studios={studioList}
          getStudio={getStudio}
          user={user}
          getTimeslots={getTimeslotList}
          timeslots={timeslots}
          validate={validate}
          isLoading={isLoading}
        />
        <OrderSession
          path="modifier"
          project={project}
          initialStudio={studio}
          initialDate={moment().add(1, 'day')}
          studios={studioList}
          getStudio={getStudio}
          user={user}
          getTimeslots={getTimeslotList}
          timeslots={timeslots}
          validate={validate}
          isUpdate
          isLoading={isLoading}
        />
        <CancelledSession
          path="annuler-seance"
          selectedStudio={studio}
          selectedDate={date}
        />
      </Router>
      <SessionBookingModal
        show={popupState}
        toggleModal={changePopupState}
        phoneForm={phoneForm}
        actions={{ cancel, confirm, modify, update }}
        type={popupType}
        date={date}
        studio={studio}
        error={error}
        isDisabled={popupType === 'confirm' && !formState.isValid}
      />
    </>
  )
}
SessionBooking.propTypes = {
  project: propTypes.objectOf(propTypes.any).isRequired,
  user: propTypes.objectOf(propTypes.any).isRequired,
  studioList: propTypes.arrayOf(propTypes.object).isRequired,
  getStudio: propTypes.func.isRequired,
  projectSession: propTypes.func.isRequired,
  timeslots: propTypes.objectOf(propTypes.any).isRequired,
  getStudioList: propTypes.func.isRequired,
  getTimeslotList: propTypes.func.isRequired,
  getStudioSessionList: propTypes.func.isRequired,
  confirmStudioSession: propTypes.func.isRequired,
  updateStudioSession: propTypes.func.isRequired,
  cancelStudioSession: propTypes.func.isRequired,
  navigate: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  fetchProjectList: propTypes.func.isRequired,
}

const mapStateToProps = (state, props) => ({
  user: getUser(state),
  project: getProjectBySlug(state, props),
  studioList: getStudios(state),
  getStudio: id => getStudioById(state, id),
  timeslots: getTimeslots(state),
  projectSession: id => getSessionByProject(state, id),
  isLoading: state.studio.isLoading,
})

const mapDispatchToProps = dispatch => ({
  getStudioList: () => dispatch(doGetStudioList()),
  getTimeslotList: (studioId, projectId, month, year) =>
    dispatch(doGetStudioTimeslots(studioId, projectId, month, year)),
  getStudioSessionList: userId => dispatch(doGetStudioSessionList(userId)),
  confirmStudioSession: (studioId, projectId, userId, startTime, tel) =>
    dispatch(
      doConfirmStudioSession(studioId, projectId, userId, startTime, tel)
    ),
  updateStudioSession: (sessionId, startTime, isB2C) =>
    dispatch(doUpdateStudioSession(sessionId, startTime, isB2C)),
  cancelStudioSession: sessionId => dispatch(doCancelStudioSession(sessionId)),
  fetchProjectList: () => dispatch(doGetProjectList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SessionBooking)
