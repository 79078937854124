import { createSelector } from 'reselect'

import { getProjectBySlug } from '../projects/selectors'
import { getUser } from '../authentication/selectors'
import { slug } from '../../helpers'

export const getCollaborators = ({ collaborator }) => collaborator.collaborators
export const getCollaboratorLoading = ({ collaborator }) =>
  collaborator.isLoading

const getCollaboratorSlugFromProps = (state, props) => props.collaboratorSlug
export const getPictures = ({ collaborator }) => collaborator.pictures

// accountManager
export const getCollaboratorsFromProject = createSelector(
  [getProjectBySlug, getCollaborators],
  (project, collaborators) =>
    project !== null && collaborators !== undefined
      ? collaborators[project.id]
      : []
)

export const getCollaborator = createSelector(
  [getCollaboratorsFromProject, getCollaboratorSlugFromProps],
  (collaborators, cSlug) => {
    let collab
    if (collaborators) {
      collab = collaborators.find(
        c => slug(`${c.lastname}-${c.firstname}`) === cSlug
      )
    }
    return collab !== undefined ? collab : null
  }
)
export const getCollaboratorPictures = createSelector(
  [getProjectBySlug, getCollaborator, getPictures],
  (project, collaborator, pictures) => {
    if (
      collaborator !== null &&
      pictures[collaborator.id] !== undefined &&
      pictures[collaborator.id][project.id] !== undefined
    )
      return pictures[collaborator.id][project.id]
    return []
  }
)

export const getUserPictures = createSelector(
  [getProjectBySlug, getUser, getPictures],
  (project, user, pictures) => {
    if (
      pictures[user.id] !== undefined &&
      pictures[user.id][project.id] !== undefined
    ) {
      return pictures[user.id][project.id]
    }
    return []
  }
)
