/* eslint-disable react/no-danger */
import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import Heading from '../../../app-components/dashed-heading'
import CollaboratorList from './CollaboratorList'
import PhotoList from './PhotoList'

import './styles.scss'
import { getProjectBySlug } from '../../../../store/projects/selectors'
import {
  getCollaboratorLoading,
  getCollaboratorsFromProject,
} from '../../../../store/collaborators/selectors'
import {
  doAddCollaborator,
  doDeleteCollaborator,
  doGetCollaboratorList,
  doSendCollaboratorList,
} from '../../../../store/collaborators/actions'
import {
  doGetProjectList,
  doDownloadAllPictures,
} from '../../../../store/projects/actions'

const ProjectDetail = ({
  project,
  collaborators,
  isLoading,
  getCollaboratorList,
  getProjectList,
  navigate,
  importCollaborators,
  importCollaborator,
  deleteCollaborator,
  downloadAllPictures,
}) => {
  useEffect(() => {
    async function init() {
      await getProjectList()
      if (project !== null) {
        await getCollaboratorList(project.id)
      } else await navigate('/compte/projets')
    }
    init()
  }, [])

  return (
    project !== null && (
      <Container className="project-details">
        <Heading className="my-4" title={project.name} />
        <h3 className="text-center">Infos du projet</h3>
        <div
          className="text-center text-primary"
          dangerouslySetInnerHTML={{ __html: project.description }}
        />

        {project.type === 'B2BPortrait' && (
          <CollaboratorList
            project={project}
            collaborators={collaborators}
            navigate={navigate}
            importCollaborators={importCollaborators}
            importCollaborator={importCollaborator}
            deleteCollaborator={deleteCollaborator}
            downloadAllPictures={downloadAllPictures}
            isLoading={isLoading}
          />
        )}
        {(project.type === 'B2BReportage' ||
          project.type === 'B2BAnimation') && (
          <PhotoList
            project={project}
            isLoading={isLoading}
            navigate={navigate}
          />
        )}
      </Container>
    )
  )
}
ProjectDetail.propTypes = {
  project: propTypes.objectOf(propTypes.any).isRequired,
  collaborators: propTypes.objectOf(propTypes.object).isRequired,
  isLoading: propTypes.bool.isRequired,
  getCollaboratorList: propTypes.func.isRequired,
  getProjectList: propTypes.func.isRequired,
  navigate: propTypes.func.isRequired,
  importCollaborators: propTypes.func.isRequired,
  importCollaborator: propTypes.func.isRequired,
  deleteCollaborator: propTypes.func.isRequired,
  downloadAllPictures: propTypes.func.isRequired,
}

const mapStateToProps = (state, props) => ({
  project: getProjectBySlug(state, props),
  collaborators: getCollaboratorsFromProject(state, props),
  isLoading: getCollaboratorLoading(state),
})

const mapDispatchToProps = dispatch => ({
  getProjectList: () => dispatch(doGetProjectList()),
  getCollaboratorList: projectId => dispatch(doGetCollaboratorList(projectId)),
  importCollaborators: (data, projectId) =>
    dispatch(doSendCollaboratorList(data, projectId)),
  importCollaborator: (collaborator, projectId) =>
    dispatch(doAddCollaborator(collaborator, projectId)),
  deleteCollaborator: collaborator =>
    dispatch(doDeleteCollaborator(collaborator)),
  downloadAllPictures: (collaborators, project) =>
    dispatch(doDownloadAllPictures(collaborators, project)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail)
