import React, { useEffect, useReducer } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/fr'
import propTypes from 'prop-types'

import CustomSelect from '../../../app-components/custom-select'
import './styles.scss'
import BookingCalendar from '../../../app-components/booking-calendar'
import { getStudiosOptions } from '../../../../helpers'

function reducer(state, action) {
  switch (action.type) {
    case 'currentDay':
      return {
        ...state,
        selectedDate: action.payload,
      }
    case 'selectTime': {
      const t = action.payload.split('H')
      return {
        ...state,
        selectedDate: state.selectedDate.set({ hour: t[0], minute: t[1] }),
      }
    }
    case 'selectStudio':
      return {
        ...state,
        selectedStudio: action.payload,
        selectedDate: null,
      }
    case 'prevMonth': {
      return {
        ...state,
        month: state.month.subtract(1, 'month'),
        selectedDate: null,
      }
    }
    case 'nextMonth': {
      return {
        ...state,
        month: state.month.add(1, 'month'),
        selectedDate: null,
      }
    }
    default:
      throw new Error()
  }
}

const OrderSession = ({
  studios,
  initialStudio,
  initialDate,
  getStudio,
  project,
  timeslots,
  getTimeslots,
  validate,
  isUpdate,
  isLoading,
}) => {
  const initialState = {
    selectedStudio: initialStudio !== null ? initialStudio.id : '',
    selectedDate: initialDate,
    month: initialDate,
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const { selectedDate, selectedStudio, month } = state

  useEffect(() => {
    async function init() {
      if (selectedStudio !== null && selectedStudio !== '') {
        await getTimeslots(
          selectedStudio,
          project.id,
          state.month.month() + 1, // zero indexed
          state.month.year()
        )
      }
    }
    init()
  }, [getTimeslots, project, state, selectedStudio])

  return (
    <div className="my-projects my-4">
      <Container>
        <Row className="text-center">
          <Col />
          <Col>
            {isUpdate ? (
              <p>
                Votre studio : <b>{`${getStudio(selectedStudio)?.name}`}</b>
              </p>
            ) : (
              <CustomSelect
                placeholder="Sélectionnez votre studio"
                options={getStudiosOptions(studios)}
                selected={value =>
                  dispatch({
                    type: 'selectStudio',
                    payload: value,
                  })
                }
                defaultValue={
                  selectedStudio !== '' &&
                  typeof getStudio(selectedStudio) !== 'undefined'
                    ? getStudio(selectedStudio).name
                    : ''
                }
                name="studios"
              />
            )}
          </Col>
          <Col />
        </Row>
        <BookingCalendar
          selectedStudio={selectedStudio}
          selectedDate={selectedDate}
          prevMonth={() => dispatch({ type: 'prevMonth' })}
          month={month}
          nextMonth={() => dispatch({ type: 'nextMonth' })}
          setDate={date => dispatch({ type: 'currentDay', payload: date })}
          setTime={time =>
            dispatch({
              type: 'selectTime',
              payload: time,
            })
          }
          timeslots={timeslots}
          loading={isLoading}
        />
        <div className="text-center">
          <Button
            disabled={
              !selectedStudio ||
              selectedDate === null ||
              selectedDate.hour() === 0
            }
            variant="primary"
            onClick={() => validate(state, isUpdate)}
          >
            VALIDER MES CHOIX
          </Button>
        </div>
      </Container>
    </div>
  )
}

OrderSession.propTypes = {
  validate: propTypes.func.isRequired,
  studios: propTypes.arrayOf(propTypes.object).isRequired,
  initialStudio: propTypes.objectOf(propTypes.any),
  initialDate: propTypes.objectOf(propTypes.any),
  project: propTypes.objectOf(propTypes.any).isRequired,
  timeslots: propTypes.objectOf(propTypes.any).isRequired,
  getTimeslots: propTypes.func.isRequired,
  getStudio: propTypes.func.isRequired,
  isUpdate: propTypes.bool,
  isLoading: propTypes.bool.isRequired,
}

OrderSession.defaultProps = {
  initialStudio: null,
  initialDate: moment(),
  isUpdate: false,
}

export default OrderSession
