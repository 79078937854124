/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import { Container } from 'react-bootstrap'
import GalleryHeader from '../gallery-header'
import PhotoGallery from '../photo-gallery'
import Heading from '../../../app-components/dashed-heading'
import LoadingIndicator from '../../../app-components/loading-indicator'

import { getProjectBySlug } from '../../../../store/projects/selectors'
import {
  getCollaborator,
  getCollaboratorPictures,
} from '../../../../store/collaborators/selectors'
import {
  doDownloadAllPictures,
  doDownloadPicture,
  doGetCollaboratorPictures,
  doSelectPicture,
  doDeleteCollaborator,
  doGetCollaboratorList,
} from '../../../../store/collaborators/actions'
import DeleteCollaboratorModal from './DeleteCollaboratorModal'
import { slug } from '../../../../helpers'
import { doGetProjectList } from '../../../../store/projects/actions'

const CollaboratorDetailView = ({
  project,
  getProjectList,
  getCollaboratorList,
  collaborator,
  pictures,
  getPictures,
  selectPicture,
  downloadPicture,
  downloadAllPictures,
  deleteCollaborator,
  isLoading,
  navigate,
}) => {
  useEffect(() => {
    async function init() {
      // refresh project list
      await getProjectList()
    }
    init()
  }, [])

  useEffect(() => {
    async function refresh() {
      // refresh (collaborators status)
      await getCollaboratorList(project.id)
    }
    if (project !== null) {
      refresh()
    }
  }, [project])

  useEffect(() => {
    async function updatePictures() {
      if (collaborator !== null)
        await getPictures(
          project,
          collaborator,
          collaborator.pivot.user_project_status
        )
      else await navigate('../')
    }

    if (project !== null) {
      updatePictures()
    }
  }, [collaborator])
  const [showDeleteModal, toggleDeleteModal] = useState(false)
  const handleDelete = () => {
    deleteCollaborator(collaborator)
    navigate(`/compte/projets/${slug(project.name)}`)
  }
  return (
    collaborator && (
      <>
        <Container>
          <Heading className="mt-5 mb-4" title={project.name} />
        </Container>
        <MediaQuery minWidth={992}>
          <div
            onClick={() => navigate(`/compte/projets/${slug(project.name)}`)}
            className="backtoproject-link"
            style={{
              maxWidth: '1200px',
              margin: 'auto',
              paddingLeft: '25px',
              paddingBottom: '10px',
            }}
          >
            <span className="circle-slider carousel-prev-icon">
              <div className="triangle-prev" />
            </span>
            Retour au projet
          </div>
          <Container>
            <GalleryHeader
              project={project}
              collaborator={collaborator}
              deleteCollab={() => toggleDeleteModal(true)}
            />
          </Container>
        </MediaQuery>
        <PhotoGallery
          project={project}
          collaborator={collaborator}
          pictures={pictures}
          selectPicture={selectPicture}
          downloadPicture={downloadPicture}
          downloadAllPictures={downloadAllPictures}
          navigate={navigate}
        />
        {isLoading && <LoadingIndicator />}
        <DeleteCollaboratorModal
          selectedList={[collaborator]}
          handleDelete={handleDelete}
          show={showDeleteModal}
          setShow={toggleDeleteModal}
        />
        <MediaQuery maxWidth={992}>
          <div
            onClick={() => navigate(`/compte/projets/${slug(project.name)}`)}
            className="backtoproject-link"
            style={{ textAlign: 'center' }}
          >
            Retour au projet
          </div>
        </MediaQuery>
      </>
    )
  )
}

CollaboratorDetailView.propTypes = {
  project: propTypes.objectOf(propTypes.any).isRequired,
  getProjectList: propTypes.func.isRequired,
  getCollaboratorList: propTypes.func.isRequired,
  collaborator: propTypes.objectOf(propTypes.any).isRequired,
  getPictures: propTypes.func.isRequired,
  pictures: propTypes.arrayOf(propTypes.array).isRequired,
  selectPicture: propTypes.func.isRequired,
  downloadPicture: propTypes.func.isRequired,
  downloadAllPictures: propTypes.func.isRequired,
  deleteCollaborator: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  navigate: propTypes.func.isRequired,
}

const mapStateToProps = (state, props) => ({
  project: getProjectBySlug(state, props),
  collaborator: getCollaborator(state, props),
  pictures: getCollaboratorPictures(state, props),
  isLoading: state.collaborator.isLoading,
})

const mapDispatchToProps = dispatch => ({
  getProjectList: () => dispatch(doGetProjectList()),
  getCollaboratorList: id => dispatch(doGetCollaboratorList(id)),
  getPictures: (project, collaborator, type) =>
    dispatch(doGetCollaboratorPictures(project, collaborator, type)),
  selectPicture: (picture, transition) =>
    dispatch(doSelectPicture(picture, transition)),
  downloadPicture: (collaborator, picture, index) =>
    dispatch(doDownloadPicture(collaborator, picture, index)),
  downloadAllPictures: (collaborator, project) =>
    dispatch(doDownloadAllPictures(collaborator, project)),
  deleteCollaborator: collaborator =>
    dispatch(doDeleteCollaborator(collaborator)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaboratorDetailView)
