import axios from 'axios'
import FileDownload from 'js-file-download'
import t from '../types'
import { slug } from '../../helpers'

axios.defaults.withCredentials = true

// Get all collaborators from a project
const getCollaboratorListRequest = () => ({
  type: t.GET_COLLABORATORS_REQUEST,
})
const getCollaboratorListSuccess = (projectId, collaborators) => ({
  type: t.GET_COLLABORATORS_SUCCESS,
  projectId,
  collaborators,
})
const getCollaboratorListFailure = error => ({
  type: t.GET_COLLABORATORS_FAILURE,
  error,
})

export const doGetCollaboratorList = projectId => async dispatch => {
  try {
    dispatch(getCollaboratorListRequest())
    const { data } = await axios.get(
      `${process.env.GATSBY_BOOKER_URL}/projects/${projectId}/collaborators`
    )
    dispatch(getCollaboratorListSuccess(projectId, data))
  } catch (e) {
    dispatch(getCollaboratorListFailure(e))
  }
}

// get pictures from collaborator by type
const getCollaboratorPicturesRequest = () => ({
  type: t.GET_COLLABORATOR_PICTURES_REQUEST,
})
const getCollaboratorPicturesSuccess = (
  pictures,
  collaboratorId,
  projectId
) => ({
  type: t.GET_COLLABORATOR_PICTURES_SUCCESS,
  pictures,
  collaboratorId,
  projectId,
})
const getCollaboratorPicturesFailure = error => ({
  type: t.GET_COLLABORATOR_PICTURES_FAILURE,
  error,
})

export const doGetCollaboratorPictures = (
  project,
  collaborator,
  type
) => async dispatch => {
  try {
    dispatch(getCollaboratorPicturesRequest())

    const { data } = await axios.get(
      `${process.env.GATSBY_BOOKER_URL}/users/${collaborator.id}/projects/${project.id}/pictures/${type}`
    )
    dispatch(getCollaboratorPicturesSuccess(data, collaborator.id, project.id))
  } catch (e) {
    dispatch(getCollaboratorPicturesFailure(e))
  }
}

const selectPictureRequest = () => ({
  type: t.SELECT_PICTURE_REQUEST,
})
const selectPictureSuccess = picture => ({
  type: t.SELECT_PICTURE_SUCCESS,
  picture,
})
const selectPictureFailure = error => ({
  type: t.SELECT_PICTURE_FAILURE,
  error,
})

export const doSelectPicture = (picture, transition) => async dispatch => {
  try {
    dispatch(selectPictureRequest())
    const { data } = await axios.put(
      `${process.env.GATSBY_BOOKER_URL}/pictures/${picture.id}/transition/${transition}`
    )
    dispatch(selectPictureSuccess(data))
  } catch (e) {
    dispatch(selectPictureFailure(e))
  }
}

export const doSelectMultiplePictures =
  (picturesId, transition) => async (dispatch) => {
    try {
      dispatch(selectPictureRequest())
      const { data } = await axios.put(
        `${process.env.GATSBY_BOOKER_URL}/pictures/transition/${transition}`,
        { picturesId }
      )
      dispatch(selectPictureSuccess(data))
      return true
    } catch (e) {
      dispatch(selectPictureFailure(e))
      return false
    }
  }

// download a picture for a collaborator
const downloadPictureRequest = () => ({
  type: t.DOWNLOAD_PICTURE_REQUEST,
})

const downloadPictureSuccess = () => ({
  type: t.DOWNLOAD_PICTURE_SUCCESS,
})

const downloadPictureFailure = error => ({
  type: t.DOWNLOAD_PICTURE_FAILURE,
  error,
})

export const doDownloadPicture = (
  collaborator,
  picture,
  index
) => async dispatch => {
  try {
    dispatch(downloadPictureRequest())
    const {
      data,
    } = await axios.get(
      `${process.env.GATSBY_BOOKER_URL}/pictures/${picture.id}/download`,
      { responseType: 'blob' }
    )
    FileDownload(
      data,
      `${slug(
        `${collaborator.lastname}-${collaborator.firstname}`
      )}-photo-${index + 1}.jpg`
    )
    dispatch(downloadPictureSuccess())
  } catch (e) {
    dispatch(downloadPictureFailure(e))
  }
}

// download all pictures from a collaborator
const downloadPicturesRequest = () => ({
  type: t.DOWNLOAD_PICTURES_REQUEST,
})

const downloadPicturesSuccess = () => ({
  type: t.DOWNLOAD_PICTURES_SUCCESS,
})

const downloadPicturesFailure = error => ({
  type: t.DOWNLOAD_PICTURES_FAILURE,
  error,
})

export const doDownloadAllPictures = (
  collaborator,
  project
) => async dispatch => {
  try {
    dispatch(downloadPicturesRequest())
    const {
      data,
    } = await axios.get(
      `${process.env.GATSBY_BOOKER_URL}/users/${collaborator.id}/projects/${project.id}/pictures/`,
      { responseType: 'blob' }
    )
    FileDownload(
      data,
      `${slug(
        `${collaborator.lastname}-${collaborator.firstname}-${project.name}`
      )}.zip`
    )
    dispatch(downloadPicturesSuccess())
  } catch (e) {
    dispatch(downloadPicturesFailure(e))
  }
}

const getCollaboratorsAddedSuccess = collaborators => ({
  type: t.GET_COLLABORATORS_ADDED_SUCCESS,
  collaborators,
})

// Add Collaborator list from CSV File
const sendCollaboratorListRequest = () => ({
  type: t.SEND_COLLABORATORS_REQUEST,
})
const sendCollaboratorListSuccess = collaborators => ({
  type: t.SEND_COLLABORATORS_SUCCESS,
  collaborators,
})
const sendCollaboratorListFailure = error => ({
  type: t.SEND_COLLABORATORS_FAILURE,
  error,
})

export const doSendCollaboratorList = (csv, projectId) => async dispatch => {
  try {
    dispatch(sendCollaboratorListRequest())
    const { data } = await axios.post(
      `${process.env.GATSBY_BOOKER_URL}/importcsv/${projectId}`,
      csv,
      {
        headers: { 'content-type': 'text/plain' },
      }
    )
    if (data.success === 1) {
      dispatch(sendCollaboratorListSuccess(data))
      dispatch(doGetCollaboratorList(projectId))
      if (data.users.length > 0) {
        dispatch(getCollaboratorsAddedSuccess(data.users))
      }
      return true
    }

    dispatch(sendCollaboratorListFailure(data.error))
    return data.error
  } catch (e) {
    dispatch(sendCollaboratorListFailure(e))
    return e.response.data.error
  }
}

// Add Single Collaborator from Form
export const doAddCollaborator = (collaborator, projectId) => {
  // csv header
  let collaboratorToText =
    'Nom;Prénom;Email professionnel ;Mot de passe par défaut ;Date de la séance photo (JJ-MM-AAAA)\r\n'
  // collaborator
  collaboratorToText += `${Object.values(collaborator)
    .map(val => `${val}`)
    .join(';')}\r\n`
  return doSendCollaboratorList(collaboratorToText, projectId)
}

const deleteCollaboratorRequest = () => ({
  type: t.DELETE_COLLABORATOR_REQUEST,
})
const deleteCollaboratorSuccess = () => ({
  type: t.DELETE_COLLABORATOR_SUCCESS,
})
const deleteCollaboratorFailure = error => ({
  type: t.DELETE_COLLABORATOR_FAILURE,
  error,
})

export const doDeleteCollaborator = collaborator => async dispatch => {
  try {
    dispatch(deleteCollaboratorRequest())
    const { data } = await axios.get(
      `${process.env.GATSBY_BOOKER_URL}/deleteuser/${collaborator.id}`
    )
    if (data.error) {
      throw new Error(data.error)
    }
    dispatch(deleteCollaboratorSuccess())
  } catch (e) {
    dispatch(deleteCollaboratorFailure(e))
  }
}

const updateClientAndCollaboratorRequest = () => ({
  type: t.UPDATE_CLIENT_AND_COLLABORATOR_REQUEST,
})

const updateClientAndCollaboratorSuccess = () => ({
  type: t.UPDATE_CLIENT_AND_COLLABORATOR_SUCCESS,
})

const updateClientAndCollaboratorFailure = (error) => ({
  type: t.UPDATE_CLIENT_AND_COLLABORATOR_FAILURE,
  error,
})

export const doUpdateClientAndCollaborator =
  (project, clientOrCollaborator, extraPicturesOptions) => async (dispatch) => {
    try {
      dispatch(updateClientAndCollaboratorRequest())
      let reqData = {}
      const picturePerCollaborator =
        project.picture_per_collaborator + extraPicturesOptions.length
      const additionalPictures = extraPicturesOptions.length
      reqData = {
        user_project_picture_per_collaborator: picturePerCollaborator,
        user_project_additional_pictures: additionalPictures,
      }
      await axios.post(
        `${process.env.GATSBY_BOOKER_URL}/users/${clientOrCollaborator.id}/projects/${project.id}/update/`,
        reqData
      )
      dispatch(updateClientAndCollaboratorSuccess())
    } catch (e) {
      dispatch(updateClientAndCollaboratorFailure(e))
    }
  }
