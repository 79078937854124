import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import propTypes from 'prop-types'
import upperFirst from 'lodash/upperFirst'
import ModalCard from '../../../../app-components/modal-card'
import CustomSelect from '../../../../app-components/custom-select'
import { STEPS_LABEL } from '../../../../../constants/steps'

import './styles.scss'

const PictureOptionsModal = ({
  heading,
  show,
  onHide,
  product,
  className,
  additionalPicture,
  handleSelection,
}) => {
  const [extraPictureOptions, setExtraPictureOptions] = useState(null)

  // Initialize select option for product before change
  const initOptionForPicture = () => {
    const extraModalOptionsSummaryInit = {}
    const extraModalOptionInit = {}

    // eslint-disable-next-line no-unused-expressions
    product?.options.forEach((option, index) => {
      extraModalOptionsSummaryInit[index] = {
        product_option_id: option?.product_option_id,
        product_option_value_id:
          option?.option_value?.[0]?.product_option_value_id,
        name: option?.option_value?.[0]?.name,
        price: option?.option_value?.[0]?.price,
      }
      extraModalOptionInit[option?.product_option_id] =
        option?.option_value?.[0]?.product_option_value_id
    })

    setExtraPictureOptions({
      id: additionalPicture?.id,
      product_id: product?.id,
      price: product?.price,
      quantity: 1,
      filename: additionalPicture?.raw_filename,
      options_summary: extraModalOptionsSummaryInit,
      option: extraModalOptionInit,
    })
  }

  useEffect(() => {
    if (additionalPicture !== null) {
      initOptionForPicture()
    }
  }, [additionalPicture])

  const handleChange = (selectOption, productOptionValueId) => {
    const extraModal = {}
    if (
      additionalPicture !== null &&
      extraPictureOptions.id === additionalPicture.id
    ) {
      const extraModalOptionsSummary = {}
      const extraModalOption = {}

      Object.keys(extraPictureOptions.options_summary).forEach(select => {
        if (
          extraPictureOptions.options_summary[select].product_option_id ===
          selectOption.product_option_id
        ) {
          Object.keys(selectOption.option_value).forEach(option => {
            if (
              selectOption.option_value[option].product_option_value_id ===
              productOptionValueId
            ) {
              extraModalOptionsSummary[select] = {
                product_option_id: selectOption.product_option_id,
                product_option_value_id:
                  selectOption.option_value[option].product_option_value_id,
                name: selectOption.option_value[option].name,
                price: selectOption.option_value[option].price,
              }
              extraModalOption[selectOption.product_option_id] =
                selectOption.option_value[option].product_option_value_id
            }
          })
        } else {
          extraModalOptionsSummary[select] = {
            product_option_id:
              extraPictureOptions.options_summary[select].product_option_id,
            product_option_value_id:
              extraPictureOptions.options_summary[select]
                .product_option_value_id,
            name: extraPictureOptions.options_summary[select].name,
            price: extraPictureOptions.options_summary[select].price,
          }
          extraModalOption[
            extraPictureOptions.options_summary[select].product_option_id
          ] =
            extraPictureOptions.options_summary[select].product_option_value_id
        }
      })
      extraModal.id = additionalPicture.id
      extraModal.product_id = product.id
      extraModal.price = product.price
      extraModal.quantity = 1
      extraModal.filename = additionalPicture.raw_filename
      extraModal.options_summary = extraModalOptionsSummary
      extraModal.option = extraModalOption
      setExtraPictureOptions(extraModal)
    }
  }

  const makeOptions = options => {
    const optionList = []
    options.forEach(ov => {
      optionList.push({ value: ov.product_option_value_id, label: ov.name })
    })
    return optionList
  }

  return (
    <ModalCard
      className={`cart-modal ${className}`}
      show={show}
      onHide={onHide}
      heading={heading}
    >
      {product?.options?.map(
        option =>
          Object.keys(STEPS_LABEL).includes(option.name) && (
            <>
              <Row>
                <Col>
                  <strong>{upperFirst(STEPS_LABEL[option.name])}</strong>
                </Col>
                <Col>
                  <CustomSelect
                    name={option.name}
                    options={makeOptions(option?.option_value)}
                    defaultValue={option?.option_value?.[0]?.name}
                    selected={value => {
                      handleChange(option, value)
                    }}
                    placeholder=""
                  />
                </Col>
              </Row>
            </>
          )
      )}
      <Row>
        <Col className="text-center">
          <Button
            onClick={() => {
              handleSelection(
                null,
                null,
                additionalPicture,
                extraPictureOptions
              )
              onHide()
            }}
            size="lg"
            className="mt-0 mx-2"
            variant="secondary"
          >
            AJOUTER
          </Button>
        </Col>
      </Row>
    </ModalCard>
  )
}
PictureOptionsModal.propTypes = {
  heading: propTypes.string.isRequired,
  onHide: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
  product: propTypes.objectOf(propTypes.any).isRequired,
  className: propTypes.string,
  additionalPicture: propTypes.objectOf(propTypes.any).isRequired,
  handleSelection: propTypes.func.isRequired,
}

PictureOptionsModal.defaultProps = {
  className: '',
}
export default PictureOptionsModal
