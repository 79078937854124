import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import ProjectListItem from './ProjectListItem'
import GalleryHeader from '../project-detail/gallery-header'
import {
  getIsAccountManager,
  getIsClient,
  getIsCollaborator,
  getUser,
} from '../../../store/authentication/selectors'
import {
  getProjectLoading,
  getProjects,
  getProjectSlug,
} from '../../../store/projects/selectors'
import { doGetProjectList } from '../../../store/projects/actions'
import { slug } from '../../../helpers'
import LoadingIndicator from '../../app-components/loading-indicator'

const ProjectList = ({
  getProjectList,
  isLoading,
  projects,
  isCollaborator,
  isAccountManager,
  isClient,
  user,
  getB2CProjectSlug,
}) => {
  useEffect(() => {
    async function init() {
      await getProjectList()
    }

    init()
  }, [])

  const projectDetailUrl = project => {
    const projectSlug = isClient
      ? getB2CProjectSlug(project)
      : slug(project.name)
    if (projectSlug === '') return '/compte/projets'
    const url = `/compte/projets/${projectSlug}`

    return (isCollaborator &&
      project.pivot.user_project_status === null &&
      project.is_booking_studio_collaborator === 1) ||
      (isClient && project.pivot.user_project_status === null)
      ? `${url}/reservation/reserver`
      : url
  }

  return (
    <>
      <Container>
        {!isAccountManager && (
          <MediaQuery minWidth={992}>
            <GalleryHeader collaborator={user} />
          </MediaQuery>
        )}
      </Container>
      <Container className="position-relative">
        <Row className={`${isCollaborator ? 'my-5' : 'my-4'}`}>
          {projects.map(project => (
            <Col xs={12} lg={4} key={project.id} className="mb-3 mb-lg-4">
              <Link
                className="project-list-link"
                to={projectDetailUrl(project)}
              >
                <ProjectListItem
                  project={project}
                  isCollaborator={isCollaborator}
                />
              </Link>
            </Col>
          ))}
        </Row>
        {isLoading && <LoadingIndicator />}
      </Container>
    </>
  )
}

ProjectList.propTypes = {
  getProjectList: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  projects: propTypes.arrayOf(propTypes.object).isRequired,
  isCollaborator: propTypes.bool.isRequired,
  isAccountManager: propTypes.bool.isRequired,
  isClient: propTypes.bool.isRequired,
  user: propTypes.objectOf(propTypes.any),
  getB2CProjectSlug: propTypes.func.isRequired,
}
ProjectList.defaultProps = { user: null }

const mapStateToProps = state => ({
  isCollaborator: getIsCollaborator(state),
  isAccountManager: getIsAccountManager(state),
  isClient: getIsClient(state),
  isLoading: getProjectLoading(state),
  projects: getProjects(state),
  getB2CProjectSlug: project => getProjectSlug(state, project),
  user: getUser(state),
})

const mapDispatchToProps = dispatch => ({
  getProjectList: () => dispatch(doGetProjectList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList)
