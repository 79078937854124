import React from 'react'
import { Col, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import Icon from '../../../../app-components/icon'
import { COLLABORATOR_GALLERY_STEPS as STEPS } from '../../../../../constants/steps'

import './styles.scss'

const EmptyPhotoSet = ({ step }) => (
  <Row className="empty-photoset my-4">
    {step === STEPS.SELECT && (
      <Col className="text-center">
        <Icon
          name="icon-fast-service"
          paths={5}
          iconClass="final-download-icon"
        />
        <p className="mt-4">
          Vos photos seront <strong>bientôt disponibles.</strong>
        </p>
      </Col>
    )}
    {step === STEPS.DOWNLOAD && (
      <Col className="text-center mb-4">
        <Icon
          name="icon-fast-service"
          paths={5}
          iconClass="final-download-icon"
        />
        <p className="mt-2">
          Votre sélection a bien été envoyée aux retoucheurs,
          <br className="d-none d-lg-block" /> vos photos seront{' '}
          <strong>bientôt disponibles.</strong>
        </p>
      </Col>
    )}
  </Row>
)
EmptyPhotoSet.propTypes = {
  step: propTypes.number.isRequired,
}
export default EmptyPhotoSet
