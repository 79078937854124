import React from 'react'
import propTypes from 'prop-types'
import './styles.scss'
import { Button } from 'react-bootstrap'
import Icon from '../icon'

const AdditionalPicture = ({ className, onClick, montant }) => (
  <div className={className}>
    <div>
      <p>
        <strong>Photo supplémentaire</strong>
        <br /> &Agrave; partir de <strong>{montant} &euro;</strong>
      </p>
    </div>
    <div>
      <Button variant="text" onClick={onClick} className="rounded-button">
        <Icon name="icon-ic-plus" />
      </Button>
    </div>
  </div>
)

AdditionalPicture.defaultProps = {
  className: '',
  onClick: () => {},
  montant: '',
}
AdditionalPicture.propTypes = {
  className: propTypes.string,
  onClick: propTypes.func,
  montant: propTypes.string,
}
export default AdditionalPicture
