import React from 'react'
import { Button } from 'react-bootstrap'
import propTypes from 'prop-types'
import ModalCard from '../../../app-components/modal-card'
import Icon from '../../../app-components/icon'

const DeleteCollaboratorModal = ({
  selectedList,
  handleDelete,
  show,
  setShow,
}) => {
  return (
    <ModalCard
      icon={<Icon name="icon-delete" iconClass="card-body-icon" />}
      title={
        <>
          <p className="mb-0">
            <strong>
              {`Suppression ${
                selectedList.length > 1
                  ? 'de plusieurs collaborateurs'
                  : "d'un collaborateur"
              }
          `}
            </strong>
          </p>
          <p>
            Attention, la suppression de{' '}
            {selectedList.length > 1
              ? 'ces collaborateurs'
              : 'ce collaborateur'}{' '}
            sera définitive.
          </p>
        </>
      }
      show={show}
      onHide={() => setShow(false)}
      heading="Supprimer"
    >
      <Button
        onClick={handleDelete}
        size="lg"
        className="mt-2 mx-2"
        variant="primary"
      >
        OUI
      </Button>
      <Button
        onClick={() => {
          setShow(false)
        }}
        size="lg"
        className="mt-2 mx-2"
        variant="outline-primary"
      >
        NON
      </Button>
    </ModalCard>
  )
}
DeleteCollaboratorModal.propTypes = {
  selectedList: propTypes.arrayOf(propTypes.object).isRequired,
  handleDelete: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
}
export default DeleteCollaboratorModal
