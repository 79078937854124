/* eslint-disable react/prop-types */
import React from 'react'

export default ({ className, onClick, isNext }) => (
  <div className={className} onClick={onClick}>
    <div className="circle-slider-button">
      <div className={isNext ? 'triangle-next' : 'triangle-prev'} />
    </div>
  </div>
)
