import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import propTypes from 'prop-types'

import { connect } from 'react-redux'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import RevolutCheckout from '@revolut/checkout'

import { useMediaQuery } from 'react-responsive'
import ErrorMessage from '../../form-error-message'
import { doGetOrderRc } from '../../../../store/offer/actions'

const AdditionalPicturePaymentForm = ({
  user,
  orderPublicId,
  rcOrderId,
  getOrderRc,
  handleSuccess,
}) => {
  const rcRef = useRef(null)
  const cardElementRef = useRef(null)
  const [cardErrors, setCardErrors] = useState([])
  const [paymentError, setPaymentError] = useState('')

  const [form, setForm] = useState({
    billingAddress: user.address,
    billingAddressSupplement: '',
    billingCity: user.city,
    billingZipcode: user.zip || user.zipcode,
  })
  const { billingAddress, billingAddressSupplement, billingCity, billingZipcode } = form

  const formSchema = yup.object().shape({
    billingAddress: yup.string().required(),
    billingCity: yup.string().required(),
    billingZipcode: yup.number().integer().positive().max(99999).required(),
  })

  const {
    register,
    errors,
    formState,
    setValue,
    handleSubmit: wrapSubmit,
  } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
  })

  const handleChange = ({ target: { value, name } }) => {
    // validate form on input change
    setValue(name, value, true)
    setForm({ ...form, [name]: value })
  }

  useEffect(() => {
    if (orderPublicId.length > 0) {
      RevolutCheckout(orderPublicId, process.env.GATSBY_REVOLUT_CHECKOUT_MODE).then((RC) => {
        rcRef.current = RC.createCardField({
          target: cardElementRef.current,
          hidePostcodeField: true,
          locale: 'fr',

          onValidation(validationCardErrors) {
            // error management for payment information entry
            setCardErrors(validationCardErrors)
            setPaymentError('')
          },
          onSuccess: async () => {
            const createRevolutOrder = await getOrderRc(rcOrderId)
            if (
              createRevolutOrder?.type === 'PAYMENT' && 
              (createRevolutOrder?.state === 'PROCESSING' ||
              createRevolutOrder?.state === 'AUTHORISED' ||
              createRevolutOrder?.state === 'COMPLETED')
            ) {
              handleSuccess()
            }else{
              setPaymentError(
                "Le paiement n'a pas pu être finalisé en raison d'un problème inattendu. Veuillez essayer à nouveau."
              )
            }
          },
          onError(paymentError) {
            setPaymentError(paymentError.message)
          },
          onCancel() {
            console.log('abandon de paiement')
          },
        })
        return () => {
          rcRef.current.destroy()
        }
      })
    }
  }, [orderPublicId])

  const handleFormSubmit = async (data) => {
    rcRef.current.submit({
      name: user.firstname + ' ' + user.lastname,
      email: user.email,
      billingAddress: {
        countryCode: 'FR',
        city: data.billingCity,
        streetLine1: data.billingAddress,
        streetLine2: data.billingAddressSupplement,
        postcode: data.billingZipcode,
      },
    })
  }

  const isLargeScreen = useMediaQuery({ query: '(min-device-width: 1200px)' })

  return (
    <Container className="" fluid>
      <Form onSubmit={() => wrapSubmit(handleFormSubmit(form))}>
        <Row className="mt-3">
          <Col className="text-center">
            <h4>Informations clients</h4>
          </Col>
        </Row>
        <Form.Row>
          <Form.Group
            as={Col}
            className="form-label-group"
            controlId="fullName"
          >
            <Form.Control
              type="text"
              placeholder="Nom complet"
              name="fullName"
              defaultValue={user.firstname + ' ' + user.lastname}
              disabled
            />
            <Form.Label>Nom complet</Form.Label>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} className="form-label-group" controlId="email">
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              defaultValue={user.email}
              disabled
            />
            <Form.Label>Email</Form.Label>
          </Form.Group>
        </Form.Row>
        <Row className="mt-3">
          <Col className="text-center">
            <h4>Adresse de facturation</h4>
          </Col>
        </Row>
        <Form.Row className="mb-lg-3">
          <Form.Group
            as={Col}
            xs={12}
            className="form-label-group"
            controlId="billingAddress"
          >
            <Form.Control
              type="text"
              placeholder="Adresse (rue, lieu dit, étage, etc.)*"
              name="billingAddress"
              defaultValue={user.address}
              ref={register}
              onChange={handleChange}
              isInvalid={errors.address1 !== undefined}
            />
            <Form.Label>Adresse (rue, lieu dit, étage, etc.)*</Form.Label>
            <ErrorMessage name="billingAddress" errors={errors} />
          </Form.Group>
          <Form.Group
            as={Col}
            xs={12}
            className="form-label-group"
            controlId="billingAddressSupplement"
          >
            <Form.Control
              type="text"
              placeholder="Complément d'adresse"
              name="billingAddressSupplement"
              defaultValue={''}
              ref={register}
              onChange={handleChange}
            />
            <Form.Label>Complément d'adresse</Form.Label>
          </Form.Group>
          <Form.Group
            as={Col}
            xs={12}
            lg={6}
            className="form-label-group"
            controlId="billingZipcode"
          >
            <Form.Control
              placeholder="Code postal"
              type="text"
              name="billingZipcode"
              defaultValue={user.zip || user.zipcode}
              ref={register}
              onChange={handleChange}
              isInvalid={errors.billingZipcode !== undefined}
            />
            <Form.Label>Code postal</Form.Label>
            <ErrorMessage name="billingZipcode" errors={errors} />
          </Form.Group>
          <Form.Group
            as={Col}
            xs={12}
            lg={6}
            className="form-label-group"
            controlId="billingCity"
          >
            <Form.Control
              placeholder="Ville*"
              name="billingCity"
              type="text"
              defaultValue={user.city}
              ref={register}
              onChange={handleChange}
              isInvalid={errors.billingCity !== undefined}
            />
            <Form.Label>Ville*</Form.Label>
            <ErrorMessage name="billingCity" errors={errors} />
          </Form.Group>
        </Form.Row>
        <Row className="mt-3">
          <Col className="text-center">
            <h4>Informations de paiement</h4>
          </Col>
        </Row>
        <Form.Row>
          <div>
            <label>Carte</label>
            <div name="card" ref={cardElementRef}></div>
          </div>
        </Form.Row>
        <Form.Row>
          <div className="form-field-error-message">
            <ul className={`error-label`}>
              {cardErrors.map((error) => (
                <li>
                  <span className={`error-label`}>{error.message}</span>
                </li>
              ))}
              {paymentError && (
                <li>
                  <span className={`error-label`}>{paymentError}</span>
                </li>
              )}
            </ul>
          </div>
        </Form.Row>
        <Row className="justify-content-center my-2 mb-lg-3">
          <Button
            size="lg"
            variant={formState.isValid ? 'secondary' : 'disabled'}
            onClick={() => {
              wrapSubmit(handleFormSubmit(form))
            }}
            disabled={!formState.isValid}
            // disabled={!formState.dirty || !formState.isValid}
          >
            {isLargeScreen ? 'PAYER MA COMMANDE' : 'PAYER'}
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
AdditionalPicturePaymentForm.propTypes = {
  user: propTypes.objectOf(propTypes.any).isRequired,
  orderPublicId: propTypes.string.isRequired,
  rcOrderId: propTypes.string.isRequired,
  handleSuccess: propTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  getOrderRc: (rcOrderId) => dispatch(doGetOrderRc(rcOrderId)),
})

export default connect(null, mapDispatchToProps)(AdditionalPicturePaymentForm)
