import React from 'react'
import { Modal } from 'react-bootstrap'
import Slider from 'react-slick'
import propTypes from 'prop-types'
import ArrowCircle from '../../../../app-components/arrow-circle'
import Checkbox from '../../../../app-components/checkbox'
import ImageOverlay from '../../../../app-components/image-overlay'
import Icon from '../../../../app-components/icon'

import './styles.scss'

const GallerySliderModal = ({
  initialSlide,
  show,
  onHide,
  isChecked,
  handleCheck,
  selectionDone,
  photos,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    initialSlide,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowCircle isNext />,
    prevArrow: <ArrowCircle isNext={false} />,
  }
  return (
    <Modal
      className="p-0 modal-slider gallery-modal"
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Icon name="icon-close" iconClass="close-btn" onClick={onHide} />
      </Modal.Header>
      <Modal.Body className="gallery-modal-body">
        <Slider {...settings}>
          {photos.map((item, index) => (
            <div
              className={`item-wrapper ${
                !isChecked(item) && selectionDone ? 'disabled-item' : ''
              }`}
            >
              <ImageOverlay
                key={item.id}
                detailView={false}
                img={item.url}
                checkbox={
                  <Checkbox
                    className={`slider-checkbox ${
                      !isChecked(item) && selectionDone ? 'd-none' : ''
                    }`}
                    isChecked={isChecked(item)}
                    onChange={e => handleCheck(e, index, item)}
                    square={false}
                  />
                }
              />
              <div
                className={`item-description ${selectionDone ? 'hide' : ''}`}
              >
                <p className="modal-description">
                  Vous pouvez sélectionnez cette photo
                  <br className="d-inline d-lg-none" />
                  <strong> en cliquant </strong>
                  <strong className="text-secondary">ICI.</strong>
                </p>
              </div>
            </div>
          ))}
        </Slider>
        <Icon name="icon-click-here" />
      </Modal.Body>
    </Modal>
  )
}

GallerySliderModal.propTypes = {
  initialSlide: propTypes.number.isRequired,
  show: propTypes.bool.isRequired,
  onHide: propTypes.func.isRequired,
  isChecked: propTypes.bool.isRequired,
  handleCheck: propTypes.func.isRequired,
  selectionDone: propTypes.bool.isRequired,
  photos: propTypes.arrayOf(propTypes.any).isRequired,
}

export default GallerySliderModal
